/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Button that triggers a menu update to view a single participant
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Header.Toasts.Buttons.HandleMultipleRequestButtonGroup
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import ActionButton from "~/components/Utilities/ActionButton";
import { useStrings } from "~/hooks";
import { moderationActions } from "~/state";
import OpenMenuButton from "./OpenMenuButton";

export enum HandleMultipleRequestButtonGroupTestIds {
	primaryButton = "HandleMultipleRequestButtonGroupPrimaryButton",
}

enum TokenNames {
	openMenu = "OpenMenu",
	denyAll = "DenyAll",
}

/**
 * The HandleMultipleRequestButtonGroup component
 * @param props The props ;)
 */
const HandleMultipleRequestButtonGroup: FC = () => {
	const strings = useStrings("Toasts", Object.values(TokenNames));
	const dispatch = useDispatch();

	const denyRequests = useCallback(() => {
		// Clear the state so we no longer receive notifications for this request
		dispatch(moderationActions.clearAllParticipantRequests());
	}, [dispatch]);

	return (
		<>
			<OpenMenuButton menu="participants" />
			<ActionButton
				tone="negative"
				priority="primary"
				onClick={denyRequests}
				text={strings[TokenNames.denyAll]}
				data-testid={HandleMultipleRequestButtonGroupTestIds.primaryButton}
				aria-label={strings[TokenNames.denyAll]}
			/>
		</>
	);
};

HandleMultipleRequestButtonGroup.displayName = "HandleMultipleRequestButtonGroup";

export default React.memo(HandleMultipleRequestButtonGroup);
