/**
 * @copyright Copyright 2021-2023 Epic Systems Corporation
 * @file Shared types used by alerts/modal pop-ups
 * @author Will Cooper
 * @module Epic.VideoApp.Types.Alerts
 */

import { minutesToMs } from "~/utils/dateTime";
import { IUser } from "~/web-core/interfaces";
import { IImageCaptureAlertData } from "./imageCapture";

/// CONSTANTS ///

// We will wait a total of five minutes before disconnecting a user from the lobby
// They will be notified of their time limit with one-minute to go
const LobbyTotalTimeOutValueMS = 300000; // 5 minutes
export const LobbyPopupTimeMS = 60000 + 400; // 1 minute, plus 400ms to show "0:00" when the timer ends
export const LobbyTimeToPopupMS = LobbyTotalTimeOutValueMS - LobbyPopupTimeMS; // Time until we display the popup, so it ends right at the timeout point

// If a toast alert is dismissed, don't bring it back for 10 minutes unless specified elsewhere
export const DefaultToastDismissTimeMS = 10 * 60 * 1000;

// How long the pop-up should be displayed for. The feedback survey pop-up uses this value to determine how soon to appear, and shows a countdown
// starting at this value within the pop-up
export const FeedbackSurveyPopupTimeMs = minutesToMs(1) + 400; // 1 minute, plus 400ms to show "0:00" when the timer ends

// How long to show the user about their inactivity before disconnecting them from the call. It runs during the call if there is a single user.
export const InactivityPopupTimeMS = minutesToMs(10);
export const PopupOffset = 400; // 400ms to show "0:00" when the timer ends
/// BANNERS ///

export type AlertBannerType = "local-screen-share";

/// ALERTS ///

export enum AlertType {
	genericMessage,
	disconnectChoice,
	concurrentSessionChoice,
	timeoutChoice,
	discardChoice,
	screenShareWarning,
	removeParticipant,
	endVisit,
	endVisitConfirmation,
	imageCaptureRetry,
	genericCountdown,
	inCallInactivityChoice,
}

interface IAlertBase {
	type: AlertType;
}

// Type that covers all alerts we can show
export type IAlert =
	| IGenericAlert
	| IChoiceAlert
	| IEndVisitAlert
	| IImageCaptureAlert
	| IScreenShareWarningAlert
	| IGenericCountdownAlert;

// Choice alert - show a message, and two buttons (confirm choice and cancel)
export interface IChoiceAlertBase extends IAlertBase {
	message: string;
	confirmText?: string;
	confirmHotkey?: string;
	cancelText?: string;
	cancelHotkey?: string;
}

interface ISimpleChoiceAlert extends IChoiceAlertBase {
	type:
		| AlertType.disconnectChoice
		| AlertType.concurrentSessionChoice
		| AlertType.timeoutChoice
		| AlertType.discardChoice
		| AlertType.endVisitConfirmation
		| AlertType.inCallInactivityChoice;
}

interface IChoiceAlertWithInfo<T> extends IChoiceAlertBase {
	alertData: T;
}

export interface IImageCaptureAlert extends IChoiceAlertWithInfo<IImageCaptureAlertData> {
	type: AlertType.imageCaptureRetry;
}

export interface IScreenShareWarningAlert extends IAlertBase {
	type: AlertType.screenShareWarning;
	shareParticipant: IUser | null;
}

interface IParticipantIdentity {
	identity: string;
	guid?: string;
}

export interface IRemoveParticipantAlert extends IChoiceAlertWithInfo<IParticipantIdentity> {
	type: AlertType.removeParticipant;
}

// Generic timeout alert - Show a message and a countdown with a single button to dismiss
export interface IGenericCountdownAlert extends IAlertBase {
	type: AlertType.genericCountdown;
	message: string;
	countdown: number;
}

// Generic alert - only shows a message, with an OK button
export interface IGenericAlert extends IAlertBase {
	type: AlertType.genericMessage;
	message: string;
}

export type IChoiceAlert = ISimpleChoiceAlert | IImageCaptureAlert | IRemoveParticipantAlert;

interface IEndVisitAlert extends IAlertBase {
	type: AlertType.endVisit;
}

/// TOASTS ///

export type ToastType =
	| "audio-track-muted"
	| "active-speaker"
	| "bandwidth-congestion-detected"
	| "bandwidth-congestion-grid"
	| "camera-switch-failed"
	| "image-capture-ready"
	| "image-capture-successful"
	| "image-captured"
	| "language-loading"
	| "multi-participant-waiting"
	| "multi-participant-request"
	| "remote-screen-shared"
	| "screen-share-disabled"
	| "single-participant-request"
	| "single-participant-waiting"
	| "user-moderated"
	| "chat-unread-message"
	| "video-track-muted";

export type ToastStyle = "success" | "language-loading";

export interface IToastBase {
	messageToken: string;
	messageArgs?: string[];
	toastStyle?: ToastStyle;
	dismissTimeMS?: number;
	ttlSeconds?: number;
	type?: ToastType;
}

interface IToastWithInfo<T> extends IToastBase {
	extraInfo: T;
}

interface ISimpleToast extends IToastBase {
	type:
		| "active-speaker"
		| "audio-track-muted"
		| "bandwidth-congestion-detected"
		| "bandwidth-congestion-grid"
		| "camera-switch-failed"
		| "image-capture-ready"
		| "image-capture-successful"
		| "image-captured"
		| "language-loading"
		| "multi-participant-waiting"
		| "multi-participant-request"
		| "screen-share-disabled"
		| "user-moderated"
		| "chat-unread-message"
		| "video-track-muted";
}

interface IParticipantIdentifierInfo {
	identity: string;
}

export interface IRemoteScreenSharedToast extends IToastWithInfo<IParticipantIdentifierInfo> {
	type: "remote-screen-shared";
}

export interface ISingleParticipantToast extends IToastWithInfo<IParticipantIdentifierInfo> {
	type: "single-participant-request" | "single-participant-waiting";
}

export type Toast = IRemoteScreenSharedToast | ISingleParticipantToast | ISimpleToast;

export type ToastWithId = Toast & { id: string };
