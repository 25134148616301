/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Component containing checkbox indicating skipping the hardware test
 * @author Jonathon Moore
 * @module Epic.VideoApp.Components.HardwareTest.SkipHardwareTestSection
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import { hardwareTestActions, useHardwareTestState, useUserState } from "~/state";
import { DisplayContext, EpicUserType, GuardOptionProperties } from "~/types";
import { saveUpdatedGuardOptions } from "~/utils/userGuardOptions";
import SelectionChoice from "../Utilities/SelectionChoice";
import styles from "./SkipHardwareTestSection.module.scss";

enum TokenNames {
	startMessage = "StartMessage",
	stopMessage = "StopMessage",
}

interface IProps {
	styling: "lobby" | "deviceTray";
	context?: number;
}

export enum SkipHardwareTestSectionTestIds {
	self = "SkipHardwareTestSection",
}

const SkipHardwareTestSection: FC<IProps> = (props: IProps) => {
	const { styling, context } = props;

	const skipHardwareTest = useHardwareTestState((selectors) => selectors.getSkipHardwareTest(), []);

	const displaySkipHardwareTestToggleInLobby = useHardwareTestState(
		(selectors) => selectors.getDisplaySkipHardwareTestToggleInLobby(),
		[],
	);

	const role = useUserState((selectors) => selectors.getUserType(), []);

	const userKey = useUserState((selectors) => selectors.getUserKey(), []);

	const dispatch = useDispatch();

	const strings = useStrings("SkipHardwareTestSection", Object.values(TokenNames));

	const isDevicesTray = context === DisplayContext.devicesTray;

	const lobbyHardwareTestSectionVisible = displaySkipHardwareTestToggleInLobby && !isDevicesTray;

	const deviceTraySectionVisible = !displaySkipHardwareTestToggleInLobby && isDevicesTray;

	//Need to set up function setting state for indiciating wanting to skip that can be read when join button is pressed.
	const handleSkip = useCallback(() => {
		if (!skipHardwareTest) {
			saveUpdatedGuardOptions(userKey, GuardOptionProperties.skipHardwareTest);
			dispatch(hardwareTestActions.setIsFirstHardwareTestSkip(false));
			dispatch(hardwareTestActions.setSkipHardwareTest(true));
		} else {
			saveUpdatedGuardOptions(userKey, GuardOptionProperties.stopSkipHardwareTest);
			dispatch(hardwareTestActions.setSkipHardwareTest(false));
		}
	}, [dispatch, skipHardwareTest, userKey]);

	//return null if were not dealing with a provider, someone using the QSP, or neither type of section is visible
	if (role !== EpicUserType.emp || (!lobbyHardwareTestSectionVisible && !deviceTraySectionVisible)) {
		return null;
	}

	return (
		<div className={styles[styling]} data-testid="SkipHardwareTestSection">
			<SelectionChoice
				onClick={handleSkip}
				checked={skipHardwareTest}
				label={isDevicesTray ? strings[TokenNames.stopMessage] : strings[TokenNames.startMessage]}
				selectType="multi"
				id="SelectionChoiceFormatting"
				useButtonCheck
				wide
				hardwareTest
				deviceTray={isDevicesTray}
			/>
		</div>
	);
};

SkipHardwareTestSection.displayName = "SkipHardwareTestSection";

export default SkipHardwareTestSection;
