/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Core session API functions
 * @author Noah Allen
 * @module Epic.VideoApp.WebCore.Functions.Session
 */

import { makeRequest } from "~/utils/request";

/**
 * Updates track subscription for the current user to allow them to receive data track messages
 */
export async function allowDataTrackMessages(jwt: string): Promise<void> {
	return makeRequest<void>("api/VideoCall/MediaSubscriptions/DataOnly", "POST", jwt);
}
