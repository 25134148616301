/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Header button for admitting all waiting participants
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.ParticipantList.AdmitParticipantsButton
 */

import React, { FC, useCallback } from "react";
import ActionButton from "~/components/Utilities/ActionButton";
import { IBasicAuditEvent, useAuditFeatureUse, useStrings } from "~/hooks";
import { useSendVisitAccessMessage } from "~/hooks/messaging";
import { useModerationState } from "~/state";
import { EventType, IParticipantWithName } from "~/types";
import styles from "./ParticipantList.module.scss";

interface IProps {
	waitingParticipants: IParticipantWithName[];
}

/** String tokens used by the WaitingParticipantMenuSection -> AdmitParticipantsButton component */
enum TokenNames {
	waitingHeader = "WaitingHeader",
	admitAll = "AdmitAll",
}
/**
 * The AdmitParticipantsButton component
 */
const AdmitParticipantsButton: FC<IProps> = (props) => {
	const { waitingParticipants } = props;
	const strings = useStrings("WaitingParticipantMenuSection", Object.values(TokenNames));
	const allowAccess = useSendVisitAccessMessage();
	const audioLock = useModerationState((selectors) => selectors.getAudioAdmitOption(), []);
	const videoLock = useModerationState((selectors) => selectors.getVideoAdmitOption(), []);
	const screenShareLock = useModerationState((selectors) => selectors.getScreenShareAdmitOption(), []);
	const auditFeatureUse = useAuditFeatureUse();

	// Admit all
	const onAdmitClick = useCallback(async () => {
		const events: IBasicAuditEvent[] = [];
		await Promise.all(
			waitingParticipants.map(async (current) => {
				// Allow access
				await allowAccess(current.participant.getUserIdentity(), current.displayName);

				// Collect audit events for mute or camera disable events
				if (audioLock) {
					events.push({
						feature: EventType.microphoneLocked,
						targetParticipantId: current.participant.getUserIdentity(),
					});
				}
				if (videoLock) {
					events.push({
						feature: EventType.cameraLocked,
						targetParticipantId: current.participant.getUserIdentity(),
					});
				}
				if (screenShareLock) {
					events.push({
						feature: EventType.screenSharingLocked,
						targetParticipantId: current.participant.getUserIdentity(),
					});
				}
			}),
		);
		// Batch audit all events (1 extra request)
		if (events.length > 0) {
			await auditFeatureUse(events);
		}
	}, [allowAccess, audioLock, auditFeatureUse, screenShareLock, videoLock, waitingParticipants]);

	return (
		<div className={styles["actionButtonRow"]}>
			<ActionButton
				onClick={onAdmitClick}
				priority="primary"
				tone="neutral"
				text={strings[TokenNames.admitAll]}
				aria-label={strings[TokenNames.admitAll]}
			/>
		</div>
	);
};

AdmitParticipantsButton.displayName = "AdmitParticipantsButton";

export default AdmitParticipantsButton;
