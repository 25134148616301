/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Component that handles an inactivity disconnection timer for solo users
 * @author Echo Cologlu & Trevor Roussel
 * @module Epic.VideoApp.Components.Participants.InactivityTimer
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext, useEffect, useRef } from "react";
import { useStrings } from "~/hooks";
import { alertActions, useAlertState, useRoomState, useUserState } from "~/state";
import { AlertType, EpicUserType, IChoiceAlert, InactivityPopupTimeMS, Timeout } from "~/types";
import { minutesToMs } from "~/utils/dateTime";
import { ParticipantDataContext } from "~/web-core/components";

enum TokenNames {
	timeoutWarningMessage = "TimeoutWarningMessage",
	leaveCall = "LeaveCall",
	extend = "Extend",
}

/**
 * Hook to manage inactivity timeout for solo users in a video call session.
 * @param session - The current session object.
 */
const InactivityTimer: React.FC = () => {
	const dispatch = useDispatch();

	const userType = useUserState((selectors) => selectors.getUserType(), []);
	const conferenceDateIso = useRoomState((selectors) => selectors.getConferenceDateIso(), []);
	const isConferenceTimeEstimate = useRoomState((selectors) => selectors.getIsConferenceTimeEstimate(), []);
	const { participants } = useContext(ParticipantDataContext);
	const inactivityExtended = useAlertState((selectors) => selectors.getDidRequestInactivityExtension(), []);
	const currentAlert = useAlertState((selectors) => selectors.getCurrentAlert(), []);
	const strings = useStrings("InactivityChoiceAlert", Object.values(TokenNames));

	const warnTimerRef = useRef<Timeout | undefined>(undefined);

	const singleParticipantVisitRef = useRef<boolean>(true);

	/**
	 * Clear the inactivity warning timer.
	 */
	const clearInactivityTimer = useCallback(() => {
		if (warnTimerRef.current) {
			clearTimeout(warnTimerRef.current);
			warnTimerRef.current = undefined;
		}
	}, []);

	/**
	 * Start the inactivity warning timer based on the calculated timeout duration.
	 */
	const startInactivityTimer = useCallback(() => {
		// If there are already timers running, do not start new ones
		if (warnTimerRef.current) {
			return;
		}
		const isFirstAndOnlyParticipant = singleParticipantVisitRef.current;
		const totalTimeoutMinutes = calculateTimeoutMinutes(
			isFirstAndOnlyParticipant,
			userType,
			conferenceDateIso,
			isConferenceTimeEstimate,
		);

		const totalMs = minutesToMs(totalTimeoutMinutes);

		// Show inactivity popup 10 minutes before the calculated timeout time
		const warnMs = totalMs - InactivityPopupTimeMS;
		const inactivityAlert: IChoiceAlert = {
			message: strings[TokenNames.timeoutWarningMessage],
			confirmText: strings[TokenNames.leaveCall],
			confirmHotkey: "L",
			cancelText: strings[TokenNames.extend],
			cancelHotkey: "X",
			type: AlertType.inCallInactivityChoice,
		};

		if (warnMs > 0) {
			warnTimerRef.current = setTimeout(() => {
				dispatch(alertActions.postChoiceAlert(inactivityAlert));
			}, warnMs);
		} else {
			// If somehow the timer is less than the popup time, just show the alert immediately
			// The popup will still run its full 10 minute timer
			dispatch(alertActions.postChoiceAlert(inactivityAlert));
		}
	}, [userType, conferenceDateIso, isConferenceTimeEstimate, dispatch, strings]);

	/**
	 * Handle the extension of inactivity by clearing and restarting the timers.
	 */
	if (inactivityExtended) {
		dispatch(alertActions.setDidRequestInactivityExtension(false));
		clearInactivityTimer();
		startInactivityTimer();
	}

	// Check if either the inactivity choice alert is displayed or the timer to display it is running
	const disconnectTimerRunning =
		currentAlert?.type === AlertType.inCallInactivityChoice || !!warnTimerRef.current;

	if (participants.length > 0 && disconnectTimerRunning) {
		// We have seen at least one remote participant, so do not calculate potential future timers as a solo visit
		singleParticipantVisitRef.current = false;
		if (currentAlert?.type === AlertType.inCallInactivityChoice) {
			dispatch(alertActions.clearAlert());
		}
		clearInactivityTimer();
	} else if (participants.length <= 0 && !disconnectTimerRunning) {
		// Alone in the call, start the timers
		startInactivityTimer();
	}

	// Clear timers on unmount
	useEffect(() => {
		return () => {
			clearInactivityTimer();
		};
	}, [clearInactivityTimer]);

	return null;
};

InactivityTimer.displayName = "InactivityTimer";

export default InactivityTimer;

/**
 * Calculate the timeout duration in minutes based on user type, participant status, and conference date.
 * @param isFirstAndOnlyParticipant - Whether the user is the first and only participant in the call.
 * @param userType - The type of the user (e.g., EMP).
 * @param conferenceDateIso - The ISO string of the conference date.
 * @param isConferenceTimeEstimate - Whether the visit time should be hidden.
 * @returns The timeout duration in minutes.
 */
export function calculateTimeoutMinutes(
	isFirstAndOnlyParticipant: boolean,
	userType: EpicUserType,
	conferenceDateIso: string | null,
	isConferenceTimeEstimate: boolean,
): number {
	if (!isFirstAndOnlyParticipant) {
		return 30; // 30 minutes if there were previously other participants in the call
	}

	if (isConferenceTimeEstimate || !conferenceDateIso) {
		return 120; // 120 minutes if visit time is not passed (ODVV, etc.) or not available
	}

	const currentTime = new Date();
	let timeout = userType === EpicUserType.emp ? 30 : 60; // default timeout in minutes. Wait longer if the user is a patient because they may need to wait longer for a provider to join

	const conferenceDate = new Date(conferenceDateIso);
	if (conferenceDate > currentTime) {
		timeout = timeout + Math.floor((conferenceDate.getTime() - currentTime.getTime()) / 60000); // default timeout + minutes until visit start date. Divides the time difference in milliseconds to get minutes
	}

	if (timeout > 240) {
		timeout = 240;
	} // do not allow more than 240 minutes of solo time in any case

	return timeout;
}
