/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Button to dismiss a toast
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Header.Toasts.Buttons.ToastDismissButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import ActionButton from "~/components/Utilities/ActionButton";
import BaseButton from "~/components/Utilities/BaseButton";
import { useStrings } from "~/hooks";
import Error from "~/icons/error";
import { alertActions } from "~/state";
import styles from "../Toasts.module.scss";

export enum ToastDismissButtonTestIds {
	self = "ToastDismissButton",
	iconOnly = "ToastDismissButtonIconOnly",
}

enum TokenNames {
	dismiss = "Dismiss",
	dismissText = "DismissText",
}

interface IDismissProps {
	id: string;
	noText?: boolean;
}

/**
 * The ToastButton component
 * @param props The props ;)
 */
const ToastDismissButton: FC<IDismissProps> = (props: IDismissProps) => {
	const { id, noText } = props;
	const strings = useStrings("Toasts", Object.values(TokenNames));
	const dispatch = useDispatch();

	const dismissToast = useCallback(() => {
		dispatch(alertActions.dismissToastAlert(id));
	}, [dispatch, id]);

	if (noText) {
		// Show the default "X" button
		return (
			<BaseButton
				icon={Error}
				aria-label={strings[TokenNames.dismiss]}
				onClick={dismissToast}
				keyboardShortcut={["alt", "x"]}
				rawClass={styles["dismissButton"]}
				data-testid={ToastDismissButtonTestIds.iconOnly}
			/>
		);
	}

	return (
		<ActionButton
			tone="neutral"
			priority="secondary"
			onClick={dismissToast}
			text={strings[TokenNames.dismissText]}
			data-testid={ToastDismissButtonTestIds.self}
			aria-label={strings[TokenNames.dismissText]}
		/>
	);
};

ToastDismissButton.displayName = "ToastDismissButton";

export default React.memo(ToastDismissButton);
