/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Shared state functions to handle the Get Configuration response.
 * @author Will Cooper
 * @module Epic.VideoApp.State.Combined.Configuration
 */

import { withSharedStates } from "@epic/react-redux-booster";
import { EnvironmentType, EpicUserType, IClientConfigurationDTO, IHardwareTestAuth } from "~/types";
import { getAvailableBackgrounds } from "~/utils/backgroundEffects";
import { VideoVendor } from "~/web-core/types";
import * as auth from "../auth";
import * as branding from "../branding";
import * as chat from "../chat";
import * as feedbackSurvey from "../feedbackSurvey";
import * as image from "../imageCapture";
import * as moderation from "../moderation";
import * as room from "../room";
import * as user from "../user";

/// COMBINED STATE TYPES ///

type CombinedConfigState = [
	branding.IBrandingState,
	feedbackSurvey.IFeedbackSurveyState,
	image.IImageCaptureState,
	moderation.IModerationState,
	room.IRoomState,
	user.IUserState,
	chat.IChatState,
];

type HardwareTestConfigState = [auth.IAuthState, branding.IBrandingState, room.IRoomState];

type BackgroundSupportState = [branding.IBrandingState, room.IRoomState, user.IUserState];

/// COMBINED REDUCERS ///

/**
 * Update a users configuration values returned from the GetConfig web service
 *
 * @param state shared state prior to this action
 * @param configuration configuration data to save on the client
 * @returns the new shared state after this action is applied
 */
function setConfiguration(
	state: CombinedConfigState,
	configuration: IClientConfigurationDTO,
): CombinedConfigState {
	if (!configuration) {
		return state;
	}

	const [
		prevBrandingState,
		prevFeedbackSurveyState,
		prevImageState,
		prevModerationState,
		prevRoomState,
		prevUserState,
		prevChatState,
	] = state;
	const newBrandingState = branding.setBrandingConfig(prevBrandingState, configuration.branding);
	const newChatState = chat.setChatConfig(prevChatState, { chatEnabled: configuration.chatEnabled });
	let newImageState = image.setDocumentTypes(prevImageState, configuration.documentTypes);
	newImageState = image.setNotifyImageCapture(newImageState, configuration.notifyImageCapture);

	let newUserState = user.setUserType(prevUserState, configuration.userType);
	newUserState = user.setUserPermission(newUserState, configuration.userPermissions);
	newUserState = user.setUserWaitingRoomStatus(
		newUserState,
		configuration.requiresVisitAccess && configuration.userType !== EpicUserType.emp,
	);
	newUserState = user.setIsDemoSystem(newUserState, configuration.environment === EnvironmentType.demo);

	newUserState = user.setHideGuardrails(newUserState, configuration.hideGuardrails);
	newUserState = user.setGuardDismissalTime(newUserState, configuration.guardrailDaysHidden);

	const newModerationState = moderation.setVisitRequiresAccess(
		prevModerationState,
		configuration.requiresVisitAccess,
	);

	let newRoomState = room.setLowBandwidth(prevRoomState, configuration.useLowBandwidthMode);
	newRoomState = room.setWaitingRoomUrl(newRoomState, configuration.waitingRoomUrl);
	newRoomState = room.setShouldCheckBrowserCompatibility(
		newRoomState,
		configuration.shouldCheckBrowserCompatibilityForBackground,
	);
	newRoomState = room.setVisitInfo(newRoomState, {
		patientName: configuration.patientName,
		conferenceDateIso: configuration.conferenceDateIso,
	});
	if (newRoomState.videoVendor === VideoVendor.unknown) {
		newRoomState = room.setVendor(newRoomState, configuration.vendor);
	}
	newRoomState = room.setIsConferenceTimeEstimate(newRoomState, configuration.isConferenceTimeEstimate);

	const newFeedbackSurveyState = feedbackSurvey.setFeedbackStep(
		prevFeedbackSurveyState,
		configuration.initialFeedbackState,
	);

	return [
		newBrandingState,
		newFeedbackSurveyState,
		newImageState,
		newModerationState,
		newRoomState,
		newUserState,
		newChatState,
	];
}

/**
 * Set a user's JWT and configuration for their session
 *
 * @param state shared state prior to this action
 * @param payload JWT and configuration to put into shared state
 * @returns the new shared state after this action is applied
 */
function setHardwareTestJwtAndConfig(
	state: HardwareTestConfigState,
	payload: IHardwareTestAuth,
): HardwareTestConfigState {
	const { jwt, vendorOverride, clientConfiguration } = payload;
	const [prevAuthState, prevBrandingState, prevRoomState] = state;

	const newAuthState = auth.setJWT(prevAuthState, jwt);
	const newBrandingState = branding.setBrandingConfig(
		prevBrandingState,
		clientConfiguration?.branding ?? null,
	);
	const newRoomState = room.setVendor(
		prevRoomState,
		vendorOverride ?? clientConfiguration?.vendor ?? VideoVendor.unknown,
	);

	return [newAuthState, newBrandingState, newRoomState];
}

/// COMBINED SELECTORS ///

/**
 * Determine if background effects are supported
 * @param state current shared state
 * @returns true if background effects are supported, false otherwise
 */
function getIsBackgroundsSupported(state: BackgroundSupportState): boolean {
	const [brandingState, roomState, userState] = state;

	const brandingAccess =
		!!brandingState.virtualBackgroundImages && brandingState.virtualBackgroundImages.length > 0;

	const backgroundsAvailable = getAvailableBackgrounds(
		userState.userPermissions.canAccessBlurring,
		userState.userPermissions.canAccessEpicBackgrounds,
		brandingAccess,
		userState.userPermissions.disableAllBackgrounds,
	);

	// Return true if not in low bandwidth mode and the device supports an available background
	return (
		!roomState.lowBandwidthMode &&
		(backgroundsAvailable.blur || backgroundsAvailable.epic || backgroundsAvailable.branded)
	);
}

/// BUILD IT ///

export const configurationCombinedReducers = {
	setConfiguration: withSharedStates(
		branding.state,
		feedbackSurvey.state,
		image.state,
		moderation.state,
		room.state,
		user.state,
		chat.state,
	).buildReducer(setConfiguration),
	setHardwareTestJwtAndConfig: withSharedStates(auth.state, branding.state, room.state).buildReducer(
		setHardwareTestJwtAndConfig,
	),
};

export const configurationCombinedSelectors = {
	getIsBackgroundsSupported: withSharedStates(branding.state, room.state, user.state).buildSelector(
		getIsBackgroundsSupported,
	),
};
