/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Buttons to open chat from the toast
 * @author Noah Allen
 * @module Epic.VideoApp.Components.Header.Toasts.Buttons.ChatToastButtonGroup
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useEffect } from "react";
import ActionButton from "~/components/Utilities/ActionButton";
import { useStrings } from "~/hooks";
import { combinedActions, uiActions, useUserState } from "~/state";
import { secondsToMs } from "~/utils/dateTime";
import ToastDismissButton from "./ToastDismissButton";

export enum ChatToastButtonGroupTestIds {
	openChatButton = "OpenChatButton",
}

interface IProps {
	id: string;
	ttlSeconds?: number;
}

enum TokenNames {
	openChatButtonText = "OpenChatButtonText",
}

/**
 * The ChatToastButtonGroup component
 * @param props The id of the toast
 */
const ChatToastButtonGroup: FC<IProps> = (props) => {
	const { id, ttlSeconds } = props;

	const dispatch = useDispatch();
	const strings = useStrings("ChatToastButtonGroup", Object.values(TokenNames));
	const inWaitingRoom = useUserState((selectors) => selectors.getIsUserInWaitingRoom(), []);

	useEffect(() => {
		if (ttlSeconds) {
			const timer = setTimeout(() => {
				dispatch(combinedActions.clearToast());
			}, secondsToMs(ttlSeconds));

			return () => {
				clearTimeout(timer);
			};
		}
	}, [dispatch, ttlSeconds]);

	const handleChatToggle = useCallback(() => {
		if (inWaitingRoom) {
			return;
		}
		dispatch(uiActions.setSidebarStatus("open"));
		dispatch(combinedActions.clearToast());
	}, [dispatch, inWaitingRoom]);
	return (
		<>
			<ActionButton
				tone="neutral"
				priority="primary"
				onClick={handleChatToggle}
				text={strings[TokenNames.openChatButtonText]}
				data-testid={ChatToastButtonGroupTestIds.openChatButton}
				aria-label={strings[TokenNames.openChatButtonText]}
			/>
			<ToastDismissButton id={id} />
		</>
	);
};

ChatToastButtonGroup.displayName = "ChatToastButtonGroup";

export default React.memo(ChatToastButtonGroup);
