/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Standalone Hardware Test Component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.HardwareTest.StandaloneHardwareTest
 */

import React, { FC, useCallback } from "react";
import { useStoreHardwareTest, useStrings } from "~/hooks";
import { useHardwareTestState } from "~/state";
import { HardwareTestStatus as Status } from "~/types";
import ActionButton from "../Utilities/ActionButton";
import HardwareTest from "./HardwareTest";

/**
 * The StandaloneHardwareTest component
 *
 * Uses a <HardwareTest> component to test all the user's devices, and shows the
 * appropriate <BottomButton> for retesting when either a success or failure is reached
 */
const StandaloneHardwareTest: FC = () => {
	const status = useHardwareTestState(
		(selectors) => selectors.getTestStatus({ isStandalone: true, allowOneError: false }),
		[],
	);

	useStoreHardwareTest(false);

	const tokenNames = ["CheckingYourHardware", "TestAgain"];
	const strings = useStrings("StandaloneHardwareTest", tokenNames);

	let bottomButtonText = strings["CheckingYourHardware"];
	const bottomButtonOnClick = useCallback(() => location.reload(), []);
	let disabled = true;
	let keyboardShortcut: string[] | undefined;
	if (status === Status.passed || status === Status.failed) {
		// In test mode, show a Test Again button when the test is complete, regardless of status
		bottomButtonText = strings["TestAgain"];
		disabled = false;
		keyboardShortcut = ["alt", "n"];
	}

	return (
		<HardwareTest
			mainButton={
				<ActionButton
					tone="neutral"
					priority="secondary"
					text={bottomButtonText}
					onClick={bottomButtonOnClick}
					disabled={disabled}
					keyboardShortcut={keyboardShortcut}
					aria-label={bottomButtonText}
				/>
			}
		/>
	);
};

StandaloneHardwareTest.displayName = "StandaloneHardwareTest";

export default StandaloneHardwareTest;
