/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file SimpleHeader component
 * @author Razi Rais
 * @module Epic.VideoApp.Components.Header.SimpleHeader
 */

import React, { FC } from "react";
import AccessibilityAlert from "../Alerts/AccessibilityAlert";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import LanguageSelectorButton from "./Buttons/LanguageSelectorButton";
import HeaderBar from "./HeaderBar";
import SinglePageMenu from "./Menu/SinglePageMenu";
import Toasts from "./Toasts/Toasts";

/**
 * SimpleHeader contains a header bar with the logo and language selector
 */
const SimpleHeader: FC = () => {
	return (
		<header>
			<AccessibilityAlert />
			<HeaderBar alwaysVisible>
				<LanguageSelectorButton context="header" />
			</HeaderBar>
			<SinglePageMenu menu="languages">
				<LanguageSelector />
			</SinglePageMenu>
			<Toasts />
		</header>
	);
};

SimpleHeader.displayName = "SimpleHeader";

export default SimpleHeader;
