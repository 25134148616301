/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file hook to set the camera information into state from the local stream or shared state
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.UseSelectedCamera
 */

import { useCallback, useContext, useEffect, useState } from "react";
import { useLocalTrackState } from "~/state";
import { IDevice } from "~/state/localTracks";
import { VideoSessionContext } from "~/web-core/components/VideoSessionProvider";

/**
 * Get the selected camera ID from shared state (either from the LocalVideoTrack or disabled camera)
 */
export function useSelectedCamera(): IDevice {
	const { localDeviceStream } = useContext(VideoSessionContext);

	const disabledCamera = useLocalTrackState((selectors) => selectors.getDisabledCamera(), []);
	const [cameraId, setCameraId] = useState<IDevice>({ deviceId: null, label: null, kind: null });

	const updateCamId = useCallback((): void => {
		const nullCamera: IDevice = { deviceId: null, label: null, kind: null };
		const newCameraID = localDeviceStream?.isEnabled("video")
			? localDeviceStream.getDeviceInfo("video")
			: disabledCamera ?? nullCamera;
		setCameraId(newCameraID);
	}, [localDeviceStream, disabledCamera]);

	useEffect(() => {
		updateCamId();

		localDeviceStream?.on("videoDeviceChanged", updateCamId);
		return () => {
			localDeviceStream?.off("videoDeviceChanged", updateCamId);
		};
	}, [localDeviceStream, updateCamId]);

	return cameraId;
}
