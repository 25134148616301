/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Component that determines and renders different video UI layouts (currently grid or active speaker)
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.VideoSection
 */

import React, { FC, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { alertActions, useUIState, useUserState } from "~/state";
import { resolveClassName } from "~/utils/className";
import { isMobile } from "~/utils/os";
import { ParticipantDataContext } from "~/web-core/components";
import ParticipantSection from "../Participants/ParticipantSection";
import styles from "./VideoSection.module.scss";
import WaitingRoom from "./WaitingRoom";
import GridSection from "./grid/GridSection";

interface IVideoSectionProps {
	hasImage: boolean;
}

const VideoSection: FC<IVideoSectionProps> = (props: IVideoSectionProps) => {
	const { hasImage } = props;
	const { participants } = useContext(ParticipantDataContext);
	const displayMode = useUIState((selectors) => selectors.getVideoLayout(), []);
	const inWaitingRoom = useUserState((selectors) => selectors.getIsUserInWaitingRoom(), []);
	const sidebarOpen = useUIState((selectors) => selectors.getSidebarStatus(), []) === "open";
	const waitingRoomOpen = inWaitingRoom || (participants.length <= 0 && !hasImage);
	const mobile = isMobile();
	const dispatch = useDispatch();

	useEffect(() => {
		if (inWaitingRoom) {
			dispatch(alertActions.setWaitingRoomAlert(true));
		}
	}, [dispatch, inWaitingRoom]);

	useEffect(() => {
		if (participants.length <= 0 && !hasImage && !inWaitingRoom) {
			dispatch(alertActions.setEmptyRoomAlert(true));
		}
	}, [dispatch, hasImage, inWaitingRoom, participants.length]);

	const videoSectionClassName = resolveClassName(styles, {
		videoSection: true,
		sidebarOpen: sidebarOpen,
		waitingRoom: waitingRoomOpen,
		isMobile: mobile,
	});
	// Only non EMPs should ever be stuck in the waiting room
	// Make sure we route patients that should be in the waiting room here
	return (
		<div className={videoSectionClassName}>
			{waitingRoomOpen ? (
				<WaitingRoom />
			) : displayMode === "Active Speaker" ? (
				<ParticipantSection />
			) : (
				<GridSection />
			)}
		</div>
	);
};

VideoSection.displayName = "VideoSection";

export default VideoSection;
