/**
 * @copyright Copyright 2025 Epic Systems Corporation
 * @file Function to detect if the device's video stream is interrupted
 * @module Epic.VideoApp.WebCore.Functions.DetectIPadCameraBug
 * @author Noah Allen
 */

import { secondsToMs } from "~/utils/dateTime";
import { ILocalStream, ISession } from "../interfaces";

export const MEDIA_TRACK_FIRST_TIMEOUT_MS = secondsToMs(5);
export const MEDIA_TRACK_SECOND_TIMEOUT_MS = secondsToMs(1);

/**
 * Detects if the device's video stream is interrupted
 * @returns {boolean} - True if the track is interrupted, otherwise false.
 */
export function detectTrackInterrupted(deviceStream: ILocalStream): boolean {
	const videoStreamTrack = deviceStream.getMediaStreamTrack("video");
	return videoStreamTrack?.muted ?? false;
}

/**
 * Detects if the iOS 17 camera bug is present.
 * Runs timers after camera initialization to check that track is in a playable state after a reasonable init period
 * Timers restart on visibility changes which induce the interrupted state and would otherwise cause false positives
 * @param {ISession} session - The session to check for the bug.
 * @returns {Promise<boolean>} - True if the bug is detected, otherwise false.
 */
export async function detectIPadCameraBug(session: ISession): Promise<boolean> {
	let timeoutId: NodeJS.Timeout | null = null;
	let secondTimeoutId: NodeJS.Timeout | null = null;
	return new Promise((resolve) => {
		const checkTrack = (): void => {
			const isBuggedAt5Seconds = detectTrackInterrupted(session.localUser.deviceStream);

			// Second timeout checks one second out to avoid false positives for a transient camera error
			secondTimeoutId = setTimeout(() => {
				const isBuggedAt6Seconds = detectTrackInterrupted(session.localUser.deviceStream);

				document.removeEventListener("visibilitychange", handleVisibilityChange);
				resolve(isBuggedAt5Seconds && isBuggedAt6Seconds);
			}, MEDIA_TRACK_SECOND_TIMEOUT_MS);
		};

		const startTimeouts = (): void => {
			timeoutId = setTimeout(checkTrack, MEDIA_TRACK_FIRST_TIMEOUT_MS);
		};

		const clearTimeouts = (): void => {
			if (timeoutId) {
				clearTimeout(timeoutId);
				timeoutId = null;
			}
			if (secondTimeoutId) {
				clearTimeout(secondTimeoutId);
				secondTimeoutId = null;
			}
		};

		const handleVisibilityChange = (): void => {
			if (document.visibilityState === "visible") {
				startTimeouts();
			} else {
				clearTimeouts();
			}
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);

		if (document.visibilityState === "visible") {
			startTimeouts();
		}
	});
}
