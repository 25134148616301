/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file Video Call authentication hook
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.Auth.UseVideoCallAuthentication
 */
import { IAction, useDispatch } from "@epic/react-redux-booster";
import { useCallback, useEffect, useRef } from "react";
import { ErrorTokenNames } from "~/features/generic-error/GenericError";
import { useCaseInsensitiveSearchParam, useDisconnect, useStrings } from "~/hooks";
import {
	alertActions,
	authActions,
	combinedActions,
	errorPageActions,
	hardwareTestActions,
	roomActions,
	useAuthState,
	useRoomState,
	userActions,
} from "~/state";
import { IErrorPage } from "~/state/errorPage";
import { DebuggingLogLevel } from "~/state/room";
import {
	AlertType,
	AuthFailureReason,
	IAccessTokenUpdate,
	IAuthFailure,
	IAuthorizationResults,
	IChoiceAlert,
	IClientLoggingConfig,
	IUserPreferences,
	IUserPreferencesWithEncryption,
	QueryParameters,
	instanceOfIAuthFailure,
} from "~/types";
import { DefaultImageNames } from "~/types/backgrounds";
import { onMemoryLeakAffectedChromiumVersion } from "~/utils/browser";
import { expireNonSessionCookies, getCookie } from "~/utils/cookies";
import { hoursToMs, minutesToMs, secondsToMs } from "~/utils/dateTime";
import frameMessager, { IFrameAuthFailureReason } from "~/utils/frameMessager";
import { I18n, getPreferredLocale } from "~/utils/i18n";
import { getConfigFromSessionCookie, setSessionCookies, tryExtendSessionCookies } from "~/utils/jwt";
import { configureClientLogging, debug } from "~/utils/logging";
import { removeAllQueryParamsExcept, setSessionID } from "~/utils/queryParameters";
import { ApiError, makeRequest } from "~/utils/request";
import { stringFormat } from "~/utils/strings";
import {
	decryptUserPreferences,
	loadUserPreferences,
	storeUserPreferencesKeyInCookie,
} from "~/utils/userPreferences";
import { getSkipHardwareTestGuardrails } from "../../utils/skipHardwareTestGuardrails";
import { useRebuildSessionFromCookies } from "./useRebuildSessionFromCookies";
import { useVideoCallAuthRequestParams } from "./useVideoCallAuthRequestParams";

export interface IVideoCallAuth {
	tryAuthenticate: () => Promise<void>;
	authenticate: () => Promise<void>;
	updateAccessToken: (jwt: string, sessionID: string) => void;
}

export function useVideoCallAuthentication(): IVideoCallAuth {
	const hasDoneAuth = useRef(false);

	// get the session ID and any other request parameters
	const sessionID = useCaseInsensitiveSearchParam(QueryParameters.sessionId) ?? "";
	const requestParams = useVideoCallAuthRequestParams();

	const isDisconnecting = useRoomState((selectors) => selectors.getIsDisconnecting(), []);
	const JWT = useAuthState((selectors) => selectors.getJWT(), []);
	const refreshTokenTimer = useAuthState((selectors) => selectors.getRefreshTokenTimer(), []);
	const dispatch = useDispatch();

	const rebuildSessionFromCookies = useRebuildSessionFromCookies();

	const tokenNames = ["AlreadyConnectedAlert", "AlreadyConnectedConfirm", "AlreadyConnectedCancel"];
	const strings = useStrings("useVideoCallAuthentication", tokenNames);
	const stringsRef = useRef(strings);
	useEffect(() => {
		stringsRef.current = strings;
	}, [strings]);

	const disconnect = useDisconnect();
	const disconnectRef = useRef(disconnect);

	useEffect(() => {
		disconnectRef.current = disconnect;
	}, [disconnect]);

	//Update the access token if it's getting close to expiring
	const updateAccessToken = useCallback(
		(jwt: string, sessionID: string) => {
			if (!jwt) {
				return;
			}

			refreshAccessToken(jwt)
				.then((response: IAuthorizationResults) => {
					afterVideoCallAuthentication(response, updateAccessToken, sessionID, dispatch);
				})
				.catch((error: Error) => {
					// before refresh tokens were implemented, users continued unauthenticated after their
					// access token expired. As customers enable refresh tokens, we'll allow to go on when
					// refreshing a user's access token fails, but should disconnect in the future (#1405)
					debug("Unable to refresh access token", error);
					setSessionCookies(jwt, sessionID, Date.now() + hoursToMs(4));
				});
		},
		[dispatch],
	);

	// Store the the result of authorization in state (JWT)
	// If we have a sessionID in the URL, authorization likely happened.
	// Try to grab the JWT for that session from a cookie.
	const authenticate = useCallback(async () => {
		if (JWT !== null || hasDoneAuth.current) {
			return;
		}

		hasDoneAuth.current = true;

		// concurrent sessions will be resolved before this point
		// if this moves consider if "session-" cookies should still be cleared by this function
		expireNonSessionCookies(sessionID);

		//if we somehow start calling this a second time, we should clear out the refresh token timer
		if (refreshTokenTimer) {
			clearTimeout(refreshTokenTimer);
		}

		// if we have an existing session in cookies, reconstruct that session
		const cookieSessionInfo = tryExtendSessionCookies(sessionID);
		if (cookieSessionInfo) {
			// Error handling implemented in this function
			await rebuildSessionFromCookies(sessionID, cookieSessionInfo, updateAccessToken);
			return;
		}

		dispatch(hardwareTestActions.setIsFirstHardwareTestSkip(true));

		// if we don't have an existing JWT in cookies, call the auth web service
		const newSessionID = setSessionID();
		try {
			const response = await loadAuthorization(requestParams);
			// Update the URL to show the sessionID for future retrieval
			removeAllQueryParamsExcept([QueryParameters.sessionId, QueryParameters.inIframe]);

			const {
				displayName,
				userKey,
				clientConfiguration,
				encryptedDisplayName,
				useLowBandwidthMode,
				logLevel,
				encryptedConfiguration,
			} = response;

			const clientLoggingConfig: IClientLoggingConfig = {
				logLevel: null,
				interval: 5,
			};
			if (logLevel === DebuggingLogLevel.verbose) {
				clientLoggingConfig.logLevel = "debug";
			} else if (logLevel === DebuggingLogLevel.low) {
				clientLoggingConfig.logLevel = "info";
			}

			// setup client logging, useClientLogging will actually send off requests
			configureClientLogging(dispatch, clientLoggingConfig);

			// A Smart on Fire Authentication path will call get configuration during authentication
			if (clientConfiguration) {
				const disableAllBackgrounds = await onMemoryLeakAffectedChromiumVersion();
				if (disableAllBackgrounds) {
					clientConfiguration.userPermissions.disableAllBackgrounds = true;
				}
				dispatch(combinedActions.setConfiguration(clientConfiguration));
			} else {
				// Web Pacs Only - Delete later
				dispatch(roomActions.setLowBandwidth(useLowBandwidthMode));
			}

			let userPreferences: IUserPreferences | null = null;
			if (userKey) {
				userPreferences = loadUserPreferences(userKey);
				dispatch(userActions.setUserKey(userKey));
				storeUserPreferencesKeyInCookie(newSessionID, userKey);
			}

			if (userPreferences === null) {
				userPreferences = {
					encryptedDisplayName: null,
					lastBackgroundProcessor: DefaultImageNames.none,
					preferredLocale: null,
				};
			}

			if (
				clientConfiguration.userPermissions.canNotSetDisplayName ||
				clientConfiguration.forceDisplayName
			) {
				dispatch(roomActions.setLocalDisplayName(displayName ?? ""));
			} else if (userPreferences.encryptedDisplayName === null) {
				dispatch(roomActions.setLocalDisplayName(displayName ?? ""));
				if (encryptedDisplayName) {
					userPreferences.encryptedDisplayName = encryptedDisplayName;
				}
			} else {
				const encryptedUserPreferences: IUserPreferencesWithEncryption = {
					displayName: userPreferences.encryptedDisplayName,
				};
				void decryptUserPreferences(response.jwt, encryptedUserPreferences).then(
					(decryptedUserPreferences: IUserPreferencesWithEncryption) => {
						dispatch(roomActions.setLocalDisplayName(decryptedUserPreferences.displayName));
					},
				);
			}

			const locale = getPreferredLocale(userPreferences);

			if (locale) {
				userPreferences.preferredLocale = locale;
			}
			await I18n.setLocale(locale, dispatch);
			dispatch(userActions.setPreferences(userPreferences));

			if (getSkipHardwareTestGuardrails(userKey)) {
				dispatch(hardwareTestActions.setSkipHardwareTest(true));
				dispatch(hardwareTestActions.setDisplaySkipHardwareTestToggleInLobby(false));
			}

			if (clientConfiguration.skipHardwareTest) {
				dispatch(hardwareTestActions.setSkipHardwareTest(true));
			}

			afterVideoCallAuthentication(
				response,
				updateAccessToken,
				newSessionID,
				dispatch,
				encryptedConfiguration,
			);

			// indicate that authentication succeeded to any parent windows
			frameMessager.postMessage("Epic.Video.AuthSuccess");
		} catch (error) {
			debug("Unable to complete authorization", error);
			let failureReason: AuthFailureReason | undefined;
			if (error instanceof ApiError && instanceOfIAuthFailure(error.data)) {
				failureReason = error.data?.failureReason;
				dispatch(errorPageActions.setErrorCard(getErrorPage(failureReason)));
			}
			frameMessager.postMessage("Epic.Video.AuthFailure", {
				errorReason: getIFrameMessageErrorReason(failureReason),
			});
			disconnectRef.current(true);
		}
	}, [
		JWT,
		dispatch,
		sessionID,
		updateAccessToken,
		refreshTokenTimer,
		requestParams,
		rebuildSessionFromCookies,
	]);

	const tryAuthenticate = useCallback(async () => {
		//if already authenticated or already disconnecting, don't do it again.
		if (JWT !== null || isDisconnecting) {
			return;
		}

		const activeSession = getCookie("active-session");
		if (activeSession !== "" && activeSession !== sessionID) {
			// Setup a modal to dispatch to the user
			const message = stringFormat(stringsRef.current["AlreadyConnectedAlert"], "\n");
			const confirm = stringsRef.current["AlreadyConnectedConfirm"];
			const cancel = stringsRef.current["AlreadyConnectedCancel"];
			const disconnectAlert: IChoiceAlert = {
				message: message,
				confirmText: confirm,
				confirmHotkey: "K",
				cancelText: cancel,
				cancelHotkey: "C",
				type: AlertType.concurrentSessionChoice,
			};
			// The resolution of the alert pop-up handles the potential disconnection
			// and routing of the user to the generic "you left the call page"
			dispatch(alertActions.postChoiceAlert(disconnectAlert));
		} else {
			await authenticate();
		}
	}, [JWT, isDisconnecting, dispatch, authenticate, sessionID]);

	return { tryAuthenticate, authenticate, updateAccessToken };
}

/**
 * Called after first connecting to SMART on FHIR or refreshing the access token to update state appropriately
 *
 * @param response - Response from the web server
 * @param timerCallback - Callback function when it's time to refresh the access token
 * @param sessionID - Session ID to update the cookies with
 * @param dispatch - The dispatch function
 */
function afterVideoCallAuthentication(
	response: IAccessTokenUpdate,
	timerCallback: (jwt: string, sessionID: string) => void,
	sessionID: string,
	dispatch: <T extends IAction>(action: T) => T,
	encryptedConfiguration?: string,
): void {
	let expirationInstant = Date.now() + secondsToMs(response.expirationSeconds);
	if (!response.hasRefreshToken) {
		expirationInstant += hoursToMs(3); //workaround for now to allow 4-hour calls before refresh tokens are enabled
	}

	if (!encryptedConfiguration) {
		// If this is being called from a workflow that did not update config, grab the old value from its cookie to ensure its expiration is updated
		encryptedConfiguration = getConfigFromSessionCookie(sessionID);
	}

	// Set appropriate cookie
	setSessionCookies(
		response.jwt,
		sessionID,
		expirationInstant,
		response.hasRefreshToken,
		encryptedConfiguration,
	);

	// Setup state for other components
	dispatch(authActions.setJWT(response.jwt));
	afterRetrieveAccessToken(response, timerCallback, sessionID, dispatch, expirationInstant);
}

/**
 * Called after an access token is updated to set up the refresh token timer and update state
 *
 * @param response - Response from the web server
 * @param timerCallback - Callback function when it's time to refresh the access token
 * @param sessionID - Session ID to update the cookies with
 * @param dispatch - The dispatch function
 * @param expirationInstant - Instant the access token expires. Will be calculated from response if not passed in.
 */
export function afterRetrieveAccessToken(
	response: IAccessTokenUpdate,
	timerCallback: (jwt: string, sessionID: string) => void,
	sessionID: string,
	dispatch: <T extends IAction>(action: T) => T,
	expirationInstant?: number,
): void {
	if (!expirationInstant) {
		expirationInstant = Date.now() + secondsToMs(response.expirationSeconds);
		if (!response.hasRefreshToken) {
			expirationInstant += hoursToMs(3); //workaround for now to allow 4-hour calls before refresh tokens are enabled
		}
	}

	if (response.hasRefreshToken) {
		const timerId = setTimeout(
			timerCallback,
			secondsToMs(response.expirationSeconds) - minutesToMs(5), //refresh 5 minutes before access token expiration
			response.jwt,
			sessionID,
		);
		dispatch(authActions.setRefreshTokenTimer(timerId));
	} else {
		dispatch(authActions.setRefreshTokenTimer(null));
	}
}

/**
 * Get the error page title and message to show after authentication failed
 * @param reason the reason that authentication failed
 * @returns title and message to be displayed on the error page
 */
function getErrorPage(reason?: AuthFailureReason): IErrorPage {
	switch (reason) {
		case AuthFailureReason.tooEarly:
			return {
				title: ErrorTokenNames.authTooEarlyHeader,
				message: ErrorTokenNames.authTooEarlyBody,
			};
		case AuthFailureReason.tooLate:
			return {
				title: ErrorTokenNames.authExpiredHeader,
				message: ErrorTokenNames.authExpiredBody,
			};
		case AuthFailureReason.removed:
			return {
				title: ErrorTokenNames.authRemovedHeader,
				message: ErrorTokenNames.authRemovedBody,
			};
		default:
			return {
				message: ErrorTokenNames.authFailedToConnect,
			};
	}
}

/**
 * Convert an authentication failure reason to the iframe auth failure reason
 * @param reason auth failure reason returned by /api/Auth/VideoCall
 * @returns the auth failure reason to provide in iframe message
 */
function getIFrameMessageErrorReason(reason?: AuthFailureReason): IFrameAuthFailureReason {
	switch (reason) {
		case AuthFailureReason.tooEarly:
			return "too-early";
		case AuthFailureReason.tooLate:
			return "visit-expired";
		default:
			return "unauthenticated";
	}
}

/**
 * Loads and returns session information (jwt and display name) token if the user is authenticated
 */
async function loadAuthorization(params: Record<string, string>): Promise<IAuthorizationResults> {
	return makeRequest<IAuthorizationResults, IAuthFailure>("/api/Auth/VideoCall", "GET", null, undefined, {
		queryStringData: params,
	});
}

/**
 * Refreshes an access token if the user is authenticated
 */
async function refreshAccessToken(jwt: string): Promise<IAuthorizationResults> {
	return makeRequest<IAuthorizationResults>("/api/Auth/RefreshAccessToken", "POST", jwt);
}
