/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file hook to retrieve the currently selected microphone from the localDeviceStream
 * @author Max Harkins
 * @module Epic.VideoApp.Hooks.UseSelectedMic
 */

import { useCallback, useContext, useEffect, useState } from "react";
import { IDevice } from "~/state/localTracks";
import { VideoSessionContext } from "~/web-core/components";

/**
 * Get the selected microphone information from the localDeviceStream
 */
export function useSelectedMic(): IDevice {
	const { localDeviceStream } = useContext(VideoSessionContext);
	const [micId, setMicId] = useState<IDevice>({ deviceId: null, label: null, kind: null });

	const updateMicId = useCallback((): void => {
		setMicId(localDeviceStream?.getDeviceInfo("audio") ?? { deviceId: null, label: null, kind: null });
	}, [localDeviceStream]);

	useEffect(() => {
		updateMicId();
		localDeviceStream?.on("audioDeviceChanged", updateMicId);
		return () => {
			localDeviceStream?.off("audioDeviceChanged", updateMicId);
		};
	}, [localDeviceStream, updateMicId]);

	return micId;
}
