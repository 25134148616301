/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file Card for when a user is waiting for other participants to join
 * @author Colin Walters
 * @module Epic.VideoApp.Components.VideoCall.WaitingCard
 */

import React, { ReactElement, useCallback, useContext, useMemo } from "react";
import { useStrings } from "~/hooks";
import { useModerationState, useRoomState, useSettingsState, useUserState } from "~/state";
import { formatDateDisplayString } from "~/utils/dateTime";
import { stringFormat } from "~/utils/strings";
import { ParticipantDataContext } from "~/web-core/components";
import { VideoSessionContext } from "~/web-core/components/VideoSessionProvider";
import SimpleCard from "../Cards/SimpleCard";
import NetworkQualityIndicator from "../NetworkQualityIndicator/NetworkQualityIndicator";
import styles from "./WaitingCard.module.scss";

export enum TokenNames {
	header = "Header",
	waitingHeader = "WaitingHeader",
	patientName = "PatientName",
	appointmentDateAndTime = "AppointmentDateAndTime",
	connectionStrength = "ConnectionStrength",
	waitingContextMessage = "WaitingContextMessage",
	admitContextMessage = "AdmitContextMessage",
	admitHeader = "AdmitHeader",
	estimatedAppointmentDateAndTime = "EstimatedAppointmentDateAndTime",
}

/**
 * The WaitingCard component
 */
const WaitingCard = (): ReactElement | null => {
	const patientName = useRoomState((selectors) => selectors.getPatientName(), []);
	const conferenceDateIso = useRoomState((selectors) => selectors.getConferenceDateIso(), []);
	const locale = useSettingsState((selectors) => selectors.getLocale(), []);
	const inWaitingRoom = useUserState((selectors) => selectors.getIsUserInWaitingRoom(), []);
	const requiresAccess = useModerationState((selectors) => selectors.getDoesVisitRequireAccess(), []);
	const canAdmit = useUserState((selectors) => selectors.getUserPermission("canStartEndVisit"), []);
	const strings = useStrings("WaitingCard", Object.values(TokenNames));
	const { session } = useContext(VideoSessionContext);
	const { waitingParticipants } = useContext(ParticipantDataContext);
	const isConferenceTimeEstimate = useRoomState((selectors) => selectors.getIsConferenceTimeEstimate(), []);

	const headerTextToken = getHeaderTextToken(canAdmit, waitingParticipants.length, inWaitingRoom);
	const showContextMessage = requiresAccess && (inWaitingRoom || canAdmit);
	const contextTextToken = showContextMessage ? getContextTextToken(inWaitingRoom, canAdmit) : "";

	const focusHeader = useCallback((headerRef: HTMLHeadingElement) => headerRef && headerRef.focus(), []);

	const dateDisplay = useMemo(() => {
		return formatDateDisplayString(conferenceDateIso, locale, "dateOnly");
	}, [conferenceDateIso, locale]);

	const timeDisplay = useMemo(() => {
		return formatDateDisplayString(conferenceDateIso, locale, "timeOnly");
	}, [conferenceDateIso, locale]);

	return (
		<SimpleCard centered={false}>
			<h1 className={styles["header"]} tabIndex={-1} ref={focusHeader}>
				{strings[headerTextToken]}
			</h1>
			{showContextMessage && contextTextToken && (
				<>
					<span>{strings[contextTextToken]}</span>
					<br />
				</>
			)}

			{patientName && <span>{stringFormat(strings[TokenNames.patientName], patientName)}</span>}
			{timeDisplay && dateDisplay && (
				<span>
					{isConferenceTimeEstimate &&
						stringFormat(
							strings[TokenNames.estimatedAppointmentDateAndTime],
							dateDisplay,
							timeDisplay,
						)}
					{!isConferenceTimeEstimate &&
						stringFormat(strings[TokenNames.appointmentDateAndTime], dateDisplay, timeDisplay)}
				</span>
			)}
			<span>
				<div className={styles["networkContainer"]}>
					<span className={styles["networkLabel"]}>{strings[TokenNames.connectionStrength]}</span>
					<NetworkQualityIndicator
						participant={session?.getLocalParticipant() ?? undefined}
						overrideFill="black"
						backgroundColor="grey"
						height={20}
						width={20}
					/>
				</div>
			</span>
		</SimpleCard>
	);
};

function getContextTextToken(inWaitingRoom: boolean, canAdmit: boolean): string | undefined {
	if (inWaitingRoom) {
		return TokenNames.waitingContextMessage;
	}

	if (canAdmit) {
		return TokenNames.admitContextMessage;
	}

	return undefined;
}

function getHeaderTextToken(
	canAdmit: boolean,
	waitingParticipantCount: number,
	inWaitingRoom: boolean,
): string {
	if (inWaitingRoom) {
		return TokenNames.waitingHeader;
	} else if (canAdmit && waitingParticipantCount > 0) {
		return TokenNames.admitHeader;
	}

	// default value
	return TokenNames.header;
}

WaitingCard.displayName = "WaitingCard";

export default WaitingCard;
