/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file Hook to automatically select hardware devices for the user
 * @author Colin Walters & Trevor Roussel
 * @module Epic.VideoApp.Hooks.UseAutomaticDeviceSelection
 */

// some of our supported browsers don't support the entire mediaDevices API. That's fine.
/* eslint-disable compat/compat */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext, useEffect, useRef } from "react";
import { combinedActions, useHardwareTestState, useSpeakerState } from "~/state";
import { localTrackActions } from "~/state/localTracks";
import { HardwareTestError, VideoSwitchResult } from "~/types";
import {
	IGetDevicePreference,
	getCameraPreference,
	getMicPreference,
	getSpeakerPreference,
	isInfraredLabeledVideoDevice,
	isOSDefinedAudioDevice,
} from "~/utils/device";
import { isIOS } from "~/utils/os";
import { VideoSessionContext } from "~/web-core/components";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { useAudioTrackActions, useVideoTrackActions } from "./localTracks";
import { useLocalAudioRecovery } from "./localTracks/useLocalAudioRecovery";
import { useSelectedCamera } from "./useSelectedCamera";
import { useSelectedMic } from "./useSelectedMic";

export function useAutomaticDeviceSelection(): (devices: MediaDeviceInfo[]) => Promise<void> {
	const selectedCamera = useSelectedCamera();
	const selectedMic = useSelectedMic();
	const { localDeviceStream } = useContext(VideoSessionContext);
	const isVideoStreamEnabled = useIsStreamEnabled("video", localDeviceStream);
	const selectedSpeakerId = useSpeakerState((selectors) => selectors.getSelectedSpeakerId(), []);
	const recoverAudio = useLocalAudioRecovery();

	const { session } = useContext(VideoSessionContext);

	const { switchVideoDevice, removeLocalVideoTrack } = useVideoTrackActions();
	const { switchAudioDevice, removeLocalAudioTrack } = useAudioTrackActions();
	const dispatch = useDispatch();

	const previousMicIds = useRef<string[]>([]);
	const previousSpeakerIds = useRef<string[]>([]);

	const hardwareTestError = useHardwareTestState((selectors) => selectors.getHardwareTestError(), []);

	// subscribe to updates as long as this component is rendered
	useEffect(() => {
		// for iOS, just mark the initial tracks as having been auto-selected to avoid https://bugs.webkit.org/show_bug.cgi?id=226921
		if (isIOS()) {
			dispatch(combinedActions.setHasAutoSelectedDevicesIOS());
			return;
		}
	}, [dispatch]);

	const autoSelectDevices = useCallback(
		async (devices: MediaDeviceInfo[]): Promise<void> => {
			if (!devices) {
				return;
			}

			// filter infrared cameras
			devices = devices.filter((device) => device.deviceId && !isInfraredLabeledVideoDevice(device));

			// split available devices into their different types
			const { cameras, microphones, speakers } = sortDevices(devices);

			// CAMERA
			const switchResult = await exhaustiveCameraSelection(
				cameras,
				selectedCamera.deviceId,
				switchVideoDevice,
				isVideoStreamEnabled,
			);
			if (switchResult.cameraId === null) {
				if (hardwareTestError !== HardwareTestError.permissionsError) {
					removeLocalVideoTrack();
				} else {
					// Have to set device to auto selected otherwise the call can't be joined
					dispatch(localTrackActions.setHasAutoSelectedVideo());
				}
			} else {
				// If the camera was found but could not be acquired, set it to the disabled camera to be enabled by the camera button
				if (switchResult.switchCameraResult === VideoSwitchResult.switchFailed) {
					const disabledCamera = cameras.find(
						(camera) => camera.deviceId === switchResult.cameraId,
					);
					dispatch(
						localTrackActions.setDisabledCamera({
							deviceId: disabledCamera?.deviceId ?? null,
							label: disabledCamera?.label ?? null,
							kind: "videoinput",
						}),
					);
				}
				dispatch(localTrackActions.setHasAutoSelectedVideo());
			}

			// MICROPHONE
			const newSelectedMic = autoSelectAudioDevice(
				microphones,
				previousMicIds.current,
				getMicPreference,
				selectedMic.deviceId ?? undefined,
			);
			previousMicIds.current = microphones.map((mic) => mic.deviceId);

			if (!newSelectedMic) {
				if (hardwareTestError !== HardwareTestError.permissionsError) {
					removeLocalAudioTrack();
				} else {
					// Have to set device to auto selected otherwise the call can't be joined
					dispatch(localTrackActions.setHasAutoSelectedAudio());
				}
			} else if (newSelectedMic.deviceId !== selectedMic.deviceId) {
				void switchAudioDevice(newSelectedMic);
			} else {
				// We have a single microphone device, and we are not starting fresh in the workflow
				// Refresh the audio stream to prevent lingering audio settings from the previous audio session on the same device
				if (microphones.filter((mic) => !isOSDefinedAudioDevice(mic)).length === 1) {
					recoverAudio(false);
				}
				dispatch(localTrackActions.setCanCreateAudioContext(true));
				dispatch(localTrackActions.setHasAutoSelectedAudio());
			}

			// SPEAKER
			const newSelectedSpeaker = autoSelectAudioDevice(
				speakers,
				previousSpeakerIds.current,
				getSpeakerPreference,
				selectedSpeakerId || undefined,
			);
			previousSpeakerIds.current = speakers.map((speaker) => speaker.deviceId);

			if (!newSelectedSpeaker || newSelectedSpeaker.deviceId !== selectedSpeakerId) {
				dispatch(combinedActions.setSelectedSpeaker(newSelectedSpeaker));
				if (newSelectedSpeaker) {
					session?.localUser.setAudioOutput(newSelectedSpeaker);
				}
			}
		},
		[
			selectedCamera.deviceId,
			switchVideoDevice,
			isVideoStreamEnabled,
			selectedMic.deviceId,
			selectedSpeakerId,
			hardwareTestError,
			removeLocalVideoTrack,
			dispatch,
			removeLocalAudioTrack,
			switchAudioDevice,
			recoverAudio,
			session?.localUser,
		],
	);

	return autoSelectDevices;
}

interface IDevicesByType {
	cameras: MediaDeviceInfo[];
	microphones: MediaDeviceInfo[];
	speakers: MediaDeviceInfo[];
}

/**
 * Group devices into cameras, microphones, and speakers
 *
 * @param devices list of media devices to be divided into types
 * @returns IDeviceOptions with devices split into types
 */
function sortDevices(devices: MediaDeviceInfo[]): IDevicesByType {
	devices = devices.filter((device) => device.deviceId);
	const cameras = devices.filter((device) => device.kind === "videoinput");
	const microphones = devices.filter((device) => device.kind === "audioinput");
	const speakers = devices.filter((device) => device.kind === "audiooutput");

	return { cameras, microphones, speakers };
}

/**
 * Attempt to acquire a specific camera device
 * @param camera Camera device to attempt to acquire
 * @param switchCameraCallback Callback to attempt to switch to the camera
 * @param selectedDeviceId The device ID of the currently selected camera
 * @param isCameraEnabled Whether the selected camera is currently enabled
 * @returns True if the camera was successfully acquired or no switch was needed, false otherwise
 */
async function attemptAcquireCamera(
	camera: MediaDeviceInfo,
	switchCameraCallback: (device: MediaDeviceInfo) => Promise<VideoSwitchResult>,
	selectedDeviceId: string | null,
	isCameraEnabled: boolean,
): Promise<boolean> {
	// If the camera is already selected and is already enabled, no action is needed
	if (camera.deviceId === selectedDeviceId && isCameraEnabled) {
		return Promise.resolve(true);
	}

	const switchResult = await switchCameraCallback(camera);

	// Treat either a successful switch or no switch needed as a success
	// If no switch is needed, we cannot verify if the camera can be acquired, so the logic will be deferred to when we attempt to enable the camera
	return (
		switchResult === VideoSwitchResult.switchSuccess || switchResult === VideoSwitchResult.noSwitchNeeded
	);
}

/**
 * Interface for the result of camera selection
 * @property switchCameraResult Enum to indicate if a camera was successfully acquired, failed to acquire, or if no acquisition was attempted
 * @property cameraId The device ID of the camera that was acquired, or the disabled preferred camera if no acquisition was attempted or successful
 */
interface ICameraSelectionResult {
	switchCameraResult: VideoSwitchResult;
	cameraId: string | null;
}

/**
 * Interface to help assign and sort available cameras by selection priority. Higher priority cameras will attempt to be acquired first.
 * @property info The MediaDeviceInfo object of the camera
 * @property priority The priority of the camera, with lower numbers being higher priority.
 */
interface ICameraPriority {
	info: MediaDeviceInfo;
	priority: number;
}

/**
 * Sorts cameras by priority of preference, to the following order:
 * 1. The saved 'preferred' camera. Will be stored in a cookie upon manual camera selection
 * 2. The currently selected camera
 * 3. Front-facing cameras
 * 4. All other cameras
 * @param cameras Array of camera devices from the browser
 * @param preferredCamId The device ID of the preferred camera
 * @param previousCameraId The device ID of the previously selected camera
 * @returns Cameras sorted from 'best selection' to 'worst selection'
 */
function sortCamerasByPriority(
	cameras: MediaDeviceInfo[],
	preferredCamId: string | null,
	previousCameraId: string | null,
): MediaDeviceInfo[] {
	const priorityProcessedCams: ICameraPriority[] = [];
	for (const camera of cameras) {
		let priority = 0;
		if (camera.deviceId === preferredCamId) {
			priority = 1;
		} else if (camera.deviceId === previousCameraId) {
			priority = 2;
		} else if (camera.label.toLowerCase().includes("front")) {
			priority = 3;
		} else {
			priority = 4;
		}
		priorityProcessedCams.push({ info: camera, priority: priority });
	}
	const sortedCams = priorityProcessedCams.sort((a, b) => a.priority - b.priority).map((cam) => cam.info);
	return sortedCams;
}

/**
 * Attempt to acquire a camera device by trying all available cameras.
 * Prioritizes cameras based on the following order:
 * 1. Cached camera preference
 * 2. Previously selected camera
 * 3. Front-facing cameras
 * 4. All other cameras
 * @param cameras Array of camera devices from the browser
 * @param prevSelectedId The device ID of the previously selected camera
 * @param switchCameraCallback Callback to switch to a camera
 * @returns ICameraSelectionResult indicating if a camera was successfully acquired, and the device ID that was selected
 */
async function exhaustiveCameraSelection(
	cameras: MediaDeviceInfo[],
	prevSelectedId: string | null,
	switchCameraCallback: (device: MediaDeviceInfo) => Promise<VideoSwitchResult>,
	isCameraEnabled: boolean,
): Promise<ICameraSelectionResult> {
	if (cameras.length === 0) {
		return { switchCameraResult: VideoSwitchResult.switchFailed, cameraId: null };
	}
	const preferredCameraId = getCameraPreference()?.id ?? null;
	// Sort cameras so we attempt to pick the preferred cameras first without re-testing the same camera
	const prioritySortedCameras = sortCamerasByPriority(cameras, preferredCameraId, prevSelectedId);

	// Disabled camera to fall back to if all cameras fail to acquire
	// Should simply fall back to the "most preferred" existing camera if all cameras fail
	let fallbackDisabledCameraId: string | null = null;

	for (const camera of prioritySortedCameras) {
		fallbackDisabledCameraId = fallbackDisabledCameraId || camera.deviceId;
		const switchCameraSuccess = await attemptAcquireCamera(
			camera,
			switchCameraCallback,
			prevSelectedId,
			isCameraEnabled,
		);
		if (switchCameraSuccess) {
			return { switchCameraResult: VideoSwitchResult.switchSuccess, cameraId: camera.deviceId };
		}
	}

	// If we've exhausted all cameras and none have been acquired, return false with the best camera we found
	return { switchCameraResult: VideoSwitchResult.switchFailed, cameraId: fallbackDisabledCameraId };
}

/**
 * Determine which audio device should be auto-selected
 *
 * @param devices list of available "audioinput" or "audiooutput" devices
 * @param prevDeviceIds list of devices that were previously available
 * @param getPreferredDeviceFromStorage function used to retrieve the preferred device
 * @param prevSelectedId deviceId of the previously selected device
 * @returns device that should be auto-selected, or null to indicate no device should be selected
 */
function autoSelectAudioDevice(
	devices: MediaDeviceInfo[],
	prevDeviceIds: string[],
	getPreferredDeviceFromStorage: IGetDevicePreference,
	prevSelectedId?: string,
): MediaDeviceInfo | null {
	// if we don't have options, return null to clear the previous selection
	if (!devices) {
		return null;
	}

	// filter out OS-defined devices
	const allowedDevices = devices.filter((device) => !isOSDefinedAudioDevice(device));
	if (!allowedDevices.length) {
		return null;
	}

	// determine which devices (if any) are new to the list, on first call this will be all of them
	const newDevices = allowedDevices.filter((device) => !prevDeviceIds.includes(device.deviceId));
	if (newDevices.length) {
		// NOTE: by only looking for preferred devices when they are newly added, a newly added headset/bluetooth
		// device will take precedence over a manually selected audio device. This is expected behavior.

		// if the user's preferred device was newly added, select that automatically
		const preferredDevice = getPreferredDevice(newDevices, getPreferredDeviceFromStorage);
		if (preferredDevice) {
			return preferredDevice;
		}

		// if there are any newly added headset or bluetooth devices, select those automatically
		const newHeadsetOrBluetoothDevice = getHeadsetOrBluetooth(newDevices);
		if (newHeadsetOrBluetoothDevice) {
			return newHeadsetOrBluetoothDevice;
		}
	}

	// if the previously selected device still exists, continue to use it
	const selectedDevice = allowedDevices.find((device) => device.deviceId === prevSelectedId);
	if (selectedDevice) {
		return selectedDevice;
	}

	// check if any of the other devices are headsets or bluetooth
	const headsetOrBluetoothDevice = getHeadsetOrBluetooth(allowedDevices);
	if (headsetOrBluetoothDevice) {
		return headsetOrBluetoothDevice;
	}

	// check if any of the remaining devices are the OS's designated "communications" or "default" devices
	const communicationsOrDefaultDevice = getCommunicationsOrDefaultDevice(devices);
	if (communicationsOrDefaultDevice) {
		return communicationsOrDefaultDevice;
	}

	// otherwise, select the first allowed device
	return allowedDevices[0];
}

/**
 * Find a device that matches the device returned by the getPreferredDeviceFromStorage function
 *
 * @param devices list of devices
 * @param getPreferredDeviceFromStorage function used to retrieve the preferred device cookie
 */
function getPreferredDevice(
	devices: MediaDeviceInfo[],
	getPreferredDeviceFromStorage: IGetDevicePreference,
): MediaDeviceInfo | null {
	// get the label of the preferred device
	const storedPreference = getPreferredDeviceFromStorage();
	if (!storedPreference) {
		return null;
	}
	// find a device matching the preferred device
	const preferredDevice =
		devices.find((device) => device.deviceId === storedPreference.id) ||
		devices.find((device) => device.label.toUpperCase() === storedPreference.label.toUpperCase());

	if (preferredDevice) {
		return preferredDevice;
	}
	return null;
}

/**
 * Find a device labeled as a "headset" or "bluetooth" device
 * Due to an issue with the Android device "Headset Earpiece", we're no longer looking for devices with "headset" in their label
 *
 * @param audioDevices list of "audioinput" or "audiooutput" devices
 * @returns device with "headset" or "bluetooth" in its label
 */
function getHeadsetOrBluetooth(audioDevices: MediaDeviceInfo[]): MediaDeviceInfo | null {
	if (!audioDevices) {
		return null;
	}
	// check for any devices labeled "bluetooth"
	const bluetoothDevice = audioDevices.find((device) => device.label.toLowerCase().includes("bluetooth"));
	if (bluetoothDevice) {
		return bluetoothDevice;
	}
	// no "headset" or "bluetooth" device was found
	return null;
}

/**
 * Find a device that is the OS-defined "communications" or "default" device
 *
 * @param audioDevices list of "audioinput" or "audiooutput" devices, this list should NOT have filtered out "communications" or "default" devices
 * @returns device that is the OS-defined "communications" or "default" device
 */
function getCommunicationsOrDefaultDevice(audioDevices: MediaDeviceInfo[]): MediaDeviceInfo | null {
	if (!audioDevices) {
		return null;
	}

	// get the filtered list of devices that show up in selectors
	const allowedDevices = audioDevices.filter((device) => !isOSDefinedAudioDevice(device));

	// get "communications" device
	const commDevice = audioDevices.find((device) => device.deviceId === "communications");
	if (commDevice) {
		// find the allowed device that matches the "communications" device
		const commMatch = getMatchingDevice(allowedDevices, commDevice);
		if (commMatch) {
			return commMatch;
		}
	}

	// get "default" device
	const defaultDevice = audioDevices.find((device) => device.deviceId === "default");
	if (defaultDevice) {
		// find the allowed device that matches the "default" device
		const defaultMatch = getMatchingDevice(allowedDevices, defaultDevice);
		if (defaultMatch) {
			return defaultMatch;
		}
	}

	// no "communications" or "default" device was found
	return null;
}

/**
 * Find a device whose label is a substring of the matchDevice label
 *
 * @param devices list of devices to search for a match within
 * @param matchDevice device to look for a match of
 * @returns a device in devices whose label was included in the matchDevice label, or null if no device is found
 */
function getMatchingDevice(devices: MediaDeviceInfo[], matchDevice: MediaDeviceInfo): MediaDeviceInfo | null {
	const foundDevice = devices.find((device) =>
		matchDevice.label.toLowerCase().includes(device.label.toLowerCase()),
	);
	return foundDevice || null;
}
