/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file Call Lobby Hardware Test Component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.HardwareTest.LobbyHardwareTest
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { ComponentType, FC, useEffect, useRef } from "react";
import { useStrings } from "~/hooks";
import Camera from "~/icons/camera";
import { alertActions, useHardwareTestState, useLocalTrackState, useRoomState } from "~/state";
import {
	AlertType,
	DeviceStatus,
	IChoiceAlert,
	LobbyTimeToPopupMS,
	HardwareTestStatus as Status,
} from "~/types";
import { isOnIOSVersion } from "~/utils/os";
import ActionButton from "../Utilities/ActionButton";
import HardwareTest from "./HardwareTest";
import SkipHardwareTestLoadingScreen from "./SkipHardwareTestLoadingScreen";

interface IProps {
	onJoinClick: () => void;
}

/**
 * The LobbyHardwareTest component
 *
 * Uses a <HardwareTest> component to test all the user's devices, and shows the
 * appropriate <BottomButton> for joining call on success
 */
const LobbyHardwareTest: FC<IProps> = (props: IProps) => {
	const { onJoinClick } = props;

	const status = useHardwareTestState(
		(selectors) => selectors.getTestStatus({ isStandalone: false, allowOneError: true }),
		[],
	);
	const isConnecting = useRoomState((selectors) => selectors.getIsConnecting(), []);

	const dispatch = useDispatch();

	const tokenNames = [
		"TimeoutAlert",
		"Close",
		"JoinCall",
		"CheckingHardware",
		"JoinWithAudioIssue",
		"JoinWithoutCamera",
		"JoinWithoutMicrophone",
		"TestFailed",
		"Joining",
	];

	const strings = useStrings("LobbyHardwareTest", tokenNames);

	const stringsRef = useRef(strings);
	useEffect(() => {
		stringsRef.current = strings;
	}, [strings]);

	useEffect(() => {
		// The resolution of the alert pop-up handles the potential disconnection
		// and routing of the user to the generic "you left the call page"
		const timeoutPopup = setTimeout(() => {
			// Setup a modal to dispatch to the user
			const disconnectAlert: IChoiceAlert = {
				message: stringsRef.current["TimeoutAlert"],
				confirmText: stringsRef.current["Close"],
				confirmHotkey: "C",
				cancelText: stringsRef.current["JoinCall"],
				cancelHotkey: "J",
				type: AlertType.timeoutChoice,
			};

			dispatch(alertActions.postChoiceAlert(disconnectAlert));
		}, LobbyTimeToPopupMS);

		return () => {
			clearTimeout(timeoutPopup);
		};
	}, [dispatch]);

	// pass false for allowOneError to get the raw error statuses
	const cameraStatus = useHardwareTestState((selectors) => selectors.getCameraStatus(false), []);
	const micStatus = useHardwareTestState((selectors) => selectors.getMicrophoneStatus(false), []);

	const cameraError = cameraStatus === DeviceStatus.error || cameraStatus === DeviceStatus.unknown;
	const micError = micStatus === DeviceStatus.error || micStatus === DeviceStatus.unknown;

	let bottomButtonText = strings["CheckingHardware"];
	let icon: ComponentType | undefined = undefined;
	let disabled = true;
	let keyboardShortcut: string[] | undefined;
	if (status === Status.passed) {
		if (isConnecting) {
			// show that the user is trying to join the room
			bottomButtonText = strings["Joining"];
		} else {
			icon = Camera;
			disabled = false;
			keyboardShortcut = ["alt", "j"];

			if (isOnIOSVersion("14_2")) {
				// show that the user is on an iOS 14.2 device and may experience audio issues
				bottomButtonText = strings["JoinWithAudioIssue"];
			} else if (cameraError || micError) {
				bottomButtonText = cameraError
					? strings["JoinWithoutCamera"]
					: strings["JoinWithoutMicrophone"];
			} else {
				// give the option to join call when hardware test passes
				bottomButtonText = strings["JoinCall"];
			}
		}
	} else if (status === Status.failed) {
		// Leave the "join call" text in the button, however if the test fails the button will be disabled
		bottomButtonText = strings["JoinCall"];
	}

	const firstSkip = useHardwareTestState((selectors) => selectors.getIsFirstHardwareTestSkip(), []);
	const skipHardwareTest = useHardwareTestState((selectors) => selectors.getSkipHardwareTest(), []);
	const localTrackAcquisitionStatus = useLocalTrackState(
		(selectors) => selectors.getLocalTrackAcquisitionStatus(),
		[],
	);
	const shouldHide = skipHardwareTest && firstSkip && localTrackAcquisitionStatus !== "showingWarning"; // Don't skip hardware test if errors need to be shown

	return (
		<>
			<SkipHardwareTestLoadingScreen
				status={status}
				displayLoadingScreen={shouldHide}
				onJoinClick={onJoinClick}
			/>
			<HardwareTest
				isLobby
				hideHardwareTest={shouldHide}
				mainButton={
					<ActionButton
						tone="positive"
						priority="primary"
						text={bottomButtonText}
						icon={icon}
						onClick={onJoinClick}
						disabled={disabled}
						keyboardShortcut={keyboardShortcut}
						data-testid="HardwareTestMainButton"
						aria-label={bottomButtonText}
					/>
				}
			/>
		</>
	);
};

LobbyHardwareTest.displayName = "LobbyHardwareTest";

export default LobbyHardwareTest;
