/**
 * @copyright Copyright 2025 Epic Systems Corporation
 * @file Warning banner to inform the user that they are a device impacted by iPad video bug
 * @author Noah Allen
 * @module Epic.VideoApp.Components.HardwareTest.Banners.IPadVideoBugWarning
 */

import React, { FC } from "react";
import { useStrings } from "~/hooks";
import BubbleBanner from "../../Utilities/BubbleBanner";

interface IProps {
	inline?: boolean;
}

enum TokenNames {
	title = "Title",
	warningMessage = "WarningMessage",
}

/**
 * The iPad Video Bug Warning banner component
 */
const IPadVideoBugWarning: FC<IProps> = (props) => {
	const { inline = false } = props;
	const strings = useStrings("IPadVideoBugWarning", Object.values(TokenNames));

	return (
		<BubbleBanner
			type="warning"
			title={strings[TokenNames.title]}
			message={strings[TokenNames.warningMessage]}
			inline={inline}
		/>
	);
};

IPadVideoBugWarning.displayName = "IPadVideoBugWarning";

export default IPadVideoBugWarning;
