/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Action buttons for the ScreenShareWarningAlert component
 * @author Trevor Roussel
 * @module Epic.VideoApp.Components.Alerts.Buttons.ScreenShareWarningAlertButtons
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { useCallback, useContext } from "react";
import styles from "~/components/Alerts/Alerts.module.scss";
import { ShareContext } from "~/components/ScreenShareContext";
import ActionButton from "~/components/Utilities/ActionButton";
import KeyboardShortcut from "~/components/Utilities/KeyboardShortcut";
import { useStrings } from "~/hooks";
import { alertActions } from "~/state";
import { ScreenShareWarningAlertTestIds } from "../ScreenShareWarningAlert";

enum TokenNames {
	continue = "Continue",
	cancel = "Cancel",
}

interface IProps {
	saveDismissOption: () => void;
	continueRef: React.RefObject<HTMLButtonElement>;
	cancelRef: React.RefObject<HTMLButtonElement>;
}

const ScreenShareWarningAlertButtons: React.FC<IProps> = (props) => {
	const { saveDismissOption, continueRef, cancelRef } = props;
	const dispatch = useDispatch();
	const strings = useStrings("ScreenShareWarning", Object.values(TokenNames));

	const { requestScreenShareTrack } = useContext(ShareContext);

	const continueShare = useCallback(() => {
		saveDismissOption();
		void requestScreenShareTrack?.(false);
		dispatch(alertActions.clearAlert());
	}, [dispatch, requestScreenShareTrack, saveDismissOption]);

	const cancel = useCallback(() => {
		dispatch(alertActions.clearAlert());
	}, [dispatch]);

	return (
		<span className={styles["buttons"]}>
			<ActionButton
				priority="primary"
				tone="neutral"
				onClick={continueShare}
				buttonRef={continueRef}
				id="continue-share"
				data-testid={ScreenShareWarningAlertTestIds.continueShare}
				aria-label={strings[TokenNames.continue]}
			>
				<KeyboardShortcut
					callback={continueShare}
					keyCombination={["alt", "c"]}
					forModalAlert
					text={strings[TokenNames.continue]}
				/>
			</ActionButton>
			<ActionButton
				priority="secondary"
				tone="neutral"
				onClick={cancel}
				buttonRef={cancelRef}
				id="cancel"
				data-testid={ScreenShareWarningAlertTestIds.cancel}
				aria-label={strings[TokenNames.cancel]}
			>
				<KeyboardShortcut
					callback={cancel}
					keyCombination={["alt", "l"]}
					forModalAlert
					text={strings[TokenNames.cancel]}
				/>
			</ActionButton>
		</span>
	);
};

ScreenShareWarningAlertButtons.displayName = "ScreenShareWarningAlertButtons";

export default ScreenShareWarningAlertButtons;
