/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file generic types
 * @author Colin Walters
 * @module Epic.VideoApp.Types.Generic
 */

export type ToggleState = "off" | "on";

export type Timeout = ReturnType<typeof setTimeout>;

// this should align (partially) with the event type enum in SystemEvent.cs
// ensure that any EventTypes that should be auditable ad-hoc are allowed in AuditFeatureModel.IsValid()
export enum EventType {
	screenSharingStarted = 10,
	screenSharingStopped = 11,
	microphoneLocked = 17,
	microphoneUnlocked = 18,
	cameraLocked = 19,
	cameraUnlocked = 20,
	participantAudioRequest = 21,
	participantVideoRequest = 22,
	screenSharingLocked = 27,
	screenSharingUnlocked = 28,
	participantScreenShareRequest = 29,
	launchedPictureInPicture = 51,
	automatedHardwareTestSkip = 52,
	localStorageHardwareTestSkip = 53,
}

export enum EventFlag {
	hasScreenShareAudio = 1,
}

export enum QueryParameters {
	sessionId = "sessionID",
	inIframe = "inIframe",
	skipHardwareTest = "skipHardwareTest",
	setName = "setName",
}

/**
 * Month values represent JavaScript date values, i.e. January is month 0 rather than 1
 */
export enum Month {
	january = 0,
	february = 1,
	march = 2,
	april = 3,
	may = 4,
	june = 5,
	july = 6,
	august = 7,
	september = 8,
	october = 9,
	november = 10,
	december = 11,
}

export interface ITestable {
	"data-testid"?: string;
}
