/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Hook to determine which device-specific warning banner should be shown. Refactored from older HardwareTest/Warnings/WarningBanners.tsx component
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareTest.Hooks.UseDeviceSpecificBanner
 */

import { useMemo } from "react";
import { useHardwareTestState, useUserState } from "~/state";
import { detectBrowser } from "~/utils/browser";
import { iOSDetectedAtVersion } from "~/utils/os";
import { isEmbeddedMobileView } from "~/utils/windowGlobals";
import { useIsVideoSupported } from "~/web-core/hooks/useIsVideoSupported";
import { useIsAndroid12 } from "./useIsAndroid12";

export type DeviceSpecificBanner =
	| "BrowserSupportBanner"
	| "HeadphoneAudioBanner"
	| "Android12Banner"
	| "IPadWarningBanner"
	| "None";

/**
 * Hook to get device specific warning banner
 */

export function useDeviceSpecificBanner(): DeviceSpecificBanner {
	const isFirefox = useMemo(() => {
		return detectBrowser() === "firefox";
	}, []);

	const isDemoSystem = useUserState((selectors) => selectors.getIsDemoSystem(), []);
	const isIPadCameraBugged = useHardwareTestState((selectors) => selectors.getIsIPadCameraBugged(), []);

	const android12BrokenBrowser = useIsAndroid12();

	const videoSupport = useIsVideoSupported();

	// Do not show any device-specific warning banners for the Demo team
	if (isDemoSystem) {
		return "None";
	}

	// render a warning if the browser is not supported by Twilio
	// also show the banner on Firefox due to large scale performance concerns and a myriad of other problems
	// Do not show a warning banner on embedded mobile about the browser version
	if (!isEmbeddedMobileView() && (!videoSupport || isFirefox)) {
		return "BrowserSupportBanner";
	}

	// render a warning when users may experience issues using headphones
	// but, prefer the browser support banner if that will be visible. Only show for iOS 15
	if (iOSDetectedAtVersion("15+") && !iOSDetectedAtVersion("16+")) {
		return "HeadphoneAudioBanner";
	}

	if (android12BrokenBrowser) {
		return "Android12Banner";
	}

	// render a warning when users may experience issues using iPads
	if (isIPadCameraBugged) {
		return "IPadWarningBanner";
	}

	return "None";
}
