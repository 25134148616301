/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file background selector
 * @author Liam Liden
 * @module Epic.VideoApp.Components.BackgroundSelector.BackgroundSelector
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useSelectedCamera } from "~/hooks";
import { useBackgroundProcessorsState, useHardwareTestState, useUIState } from "~/state";
import { DeviceStatus } from "~/types";
import { warn } from "~/utils/logging";
import { VideoSessionContext } from "~/web-core/components";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { ILocalStream } from "~/web-core/interfaces";
import BackgroundGallery from "./BackgroundGallery";
import BackgroundPreview from "./BackgroundPreview";
import styles from "./BackgroundSelector.module.scss";

interface IBackgroundStream {
	stream: ILocalStream | null;
	isPreview: boolean;
}

/**
 * The BackgroundSelector component
 */
const BackgroundSelector: FC = () => {
	const dispatch = useDispatch();
	const { session, localDeviceStream } = useContext(VideoSessionContext);
	const localUser = session?.localUser;
	const [streamToUse, setStreamToUse] = useState<IBackgroundStream>({ stream: null, isPreview: false });

	const cameraEnabled = useIsStreamEnabled("video", localDeviceStream);
	const cameraStatus = useHardwareTestState((selectors) => selectors.getCameraStatus(false), []);

	const publishedProcessor =
		useBackgroundProcessorsState((selectors) => selectors.getPublishedProcessorFromMap(), []) ?? null;

	const camera = useSelectedCamera();

	const [failedTrackCreation, setFailedTrackCreation] = useState(false);
	const publishedProcessorRef = useRef(publishedProcessor);
	const isMountedRef = useRef(true);
	const isCreatingPreviewTrack = useRef(false);
	const menuStateRef = useRef<string | null>();
	menuStateRef.current = useUIState((selectors) => selectors.getVisibleMenu(), []);

	// If user closes BG Effects menu during preview track creation, make sure to NOT set state and detach + stop track to avoid memory leaks
	// This useEffect should be the first to run to make sure the reference is up-to-date in other useEffects
	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	}, []);

	useEffect(() => {
		publishedProcessorRef.current = publishedProcessor;
	}, [publishedProcessor]);

	useEffect(() => {
		if (streamToUse.stream) {
			// If true, then we are using the incorrect localDeviceStream and need to switch
			if (streamToUse.isPreview === cameraEnabled) {
				if (streamToUse.isPreview) {
					stopPreviewStream(streamToUse.stream);
				}
				setStreamToUse({ stream: null, isPreview: false });
				// Otherwise, we are already using the correct localDeviceStream and can return
			} else {
				return;
			}
		}

		const createPreviewTrack = async (): Promise<void> => {
			// Do not attempt to create a preview track if we are already in the process of creating one
			// or if the menu is in the process of closing
			if (isCreatingPreviewTrack.current || menuStateRef.current !== "background") {
				return;
			}
			// If we're trying to create a preview track, set this to false to display the spinner
			// until we either get a track or know it will fail
			setFailedTrackCreation(false);
			isCreatingPreviewTrack.current = true;
			if (!localUser) {
				return;
			}
			if (cameraEnabled && localDeviceStream) {
				setStreamToUse({ stream: localDeviceStream, isPreview: false });
				isCreatingPreviewTrack.current = false;
				return;
			}
			try {
				const previewStream = await localUser.createDevicePreviewStream(camera.deviceId ?? null);
				setStreamToUse({ stream: previewStream, isPreview: true });
				if (publishedProcessorRef.current) {
					await localUser.applyVideoBackground(publishedProcessorRef.current, previewStream);
				}
				if (!isMountedRef.current) {
					// User closed menu (unmounted) before track was created so cleanup
					stopPreviewStream(previewStream);
				}
			} catch (error) {
				// If track creation fails, we will use the disabled camera indicator rather than a spinner
				setFailedTrackCreation(true);
				warn("Preview track creation failed", error);
			} finally {
				isCreatingPreviewTrack.current = false;
			}
		};
		if (cameraStatus === DeviceStatus.success) {
			// Set a small timeout to give time to update the menu status so we don't create a preview localDeviceStream during menu closing
			void setTimeout(() => void createPreviewTrack(), 50);
		}
	}, [dispatch, cameraStatus, camera, streamToUse, localUser, cameraEnabled, localDeviceStream]);

	function stopPreviewStream(localDeviceStream: ILocalStream): void {
		void localDeviceStream.cleanUp();
	}

	// Cleanup previewVideoTrack on unmount (exiting BG Effects menu)
	// If unmount happens before previewVideoTrack is created, this won't cleanup correctly (why we need isMounted in above useEffect)
	useEffect(() => {
		return () => {
			if (isMountedRef.current === false && streamToUse.stream && streamToUse.isPreview) {
				stopPreviewStream(streamToUse.stream);
			}
		};
	}, [streamToUse]);

	return (
		<div className={styles["backgroundSelector"]}>
			{!cameraEnabled && (
				<BackgroundPreview
					stream={streamToUse.stream}
					disabled={cameraStatus !== DeviceStatus.success || failedTrackCreation}
				/>
			)}
			<BackgroundGallery applyStream={streamToUse.stream} noPreview={cameraEnabled} />
		</div>
	);
};

BackgroundSelector.displayName = "BackgroundSelector";

export default BackgroundSelector;
