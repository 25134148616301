/**
 * @copyright Copyright 2021 - 2024 Epic Systems Corporation
 * @file types related to the user preferences and user specific data
 * @author Steven Anderson
 * @module Epic.VideoApp.Types.User
 */

import { FeedbackPageStep } from "~/types/survey";
import { VideoVendor } from "~/web-core/types";
import { IBrandingConfig } from "./branding";
import { ITelemedicineDocType } from "./imageCapture";

export enum EpicUserType {
	unknown = 0,
	emp = 1,
	wpr = 2,
	rla = 3,
}

export interface IClientConfigurationDTO {
	userPermissions: IUserPermissions;
	documentTypes: ITelemedicineDocType[];
	requiresVisitAccess: boolean;
	patientName: string;
	conferenceDateIso: string;
	userType: EpicUserType;
	useLowBandwidthMode: boolean;
	waitingRoomUrl: string;
	initialFeedbackState: FeedbackPageStep;
	environment?: EnvironmentType;
	branding: IBrandingConfig | null;
	hideGuardrails: boolean;
	guardrailDaysHidden: number;
	notifyImageCapture: boolean;
	forceDisplayName: boolean;
	shouldCheckBrowserCompatibilityForBackground: boolean;
	vendor: VideoVendor;
	chatEnabled: boolean;
	skipHardwareTest: boolean;
	clientVideoVisitInstant: string;
	isConferenceTimeEstimate: boolean;
}

export enum EnvironmentType {
	prd = "PRD",
	tst = "TST",
	demo = "DEMO",
}

export interface IUserPermissions {
	canShareScreen: boolean;
	canCaptureImage: boolean;
	canModerateVisit: boolean;
	canStartEndVisit: boolean;
	canNotSetDisplayName: boolean;
	canAccessBlurring: boolean;
	canAccessEpicBackgrounds: boolean;
	canTestSpeaker: boolean;
	disableAllBackgrounds: boolean;
}

export interface IBackgroundAccess {
	blurAccess: boolean;
	epicBackgroundsAccess: boolean;
}

export interface IUserPreferences {
	encryptedDisplayName: string | null;
	preferredLocale: string | null;
	lastBackgroundProcessor: string;
}

// State settings for optional guardrails we allow users to individually disable
export interface IUserGuardOptions {
	dismissedScreenShareWarning: boolean;
	skipHardwareTest: boolean;
}

// Used to log dismissal time within localStorage
export interface ISaveUserGuardOptions {
	// If a user dismisses the warning, we log the date of dismissal as a UTC string and check if a refresh is due at load time
	dismissedScreenShareWarning: string | null;
	skipHardwareTest: string | null;
}

export enum GuardOptionProperties {
	dismissedScreenShareWarning = 0,
	skipHardwareTest = 1,
	stopSkipHardwareTest = 2,
}

export interface IUserPreferencesWithEncryption {
	displayName: string;
}

export interface IChatNegotiateResponse {
	serviceAddress: string;
	token: string;
}
