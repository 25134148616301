/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Definitions for stream events
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Events.StreamEvents
 */

import { IDimensions } from "~/types";
import { IEVCAudioLevelEvent } from "./helperEvents";
import { IEVCEvent } from "./interfaces/evcEmitter";

export interface IEVCDimensionEvent extends IEVCEvent<"videoDimensionsChanged"> {
	newDim: IDimensions;
}

export interface IEVCMediaTrackEvent extends IEVCEvent<"videoReady" | "audioReady"> {
	track: MediaStreamTrack;
}

export interface IEVCStreamEventMap {
	videoEnabled: IEVCEvent<"videoEnabled">;
	videoDisabled: IEVCEvent<"videoDisabled">;
	videoDimensionsChanged: IEVCDimensionEvent;
	audioEnabled: IEVCEvent<"audioEnabled">;
	audioDisabled: IEVCEvent<"audioDisabled">;
	audioStopped: IEVCEvent<"audioStopped">;
	videoReady: IEVCMediaTrackEvent;
	audioReady: IEVCMediaTrackEvent;
	videoBandwidthLow: IEVCEvent<"videoBandwidthLow">;
	videoBandwidthNormal: IEVCEvent<"videoBandwidthNormal">;
	audioDeviceChanged: IEVCEvent<"audioDeviceChanged">;
	videoDeviceChanged: IEVCEvent<"videoDeviceChanged">;
	audioLevelUpdated: IEVCAudioLevelEvent;
}

export type MediaEvent = "videoEnabled" | "videoDisabled" | "audioEnabled" | "audioDisabled";
