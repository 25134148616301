/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Session for Daily
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Vendor.Daily.Implementations.DailySession
 */

import {
	DailyCall,
	DailyEventObject,
	DailyEventObjectActiveSpeakerChange,
	DailyEventObjectFatalError,
	DailyEventObjectParticipant,
} from "@daily-co/daily-js";
import { makeRequest } from "~/utils/request";
import { EVCEmitter, IEVCSessionEventMap } from "~/web-core/events";
import { allowDataTrackMessages } from "~/web-core/functions/session";
import { ILocalStream, ILocalUser, ISession, VendorError } from "~/web-core/interfaces";
import { DeviceKind, IConnectOptions, IConnectResponseDTO, SessionConnectionStatus } from "~/web-core/types";
import { dailyErrorToVendorError } from "../functions/dailyErrorUtils";
import { exponentialBackoff } from "../functions/utils";
import {
	handleCpuLoadChangeEvent,
	handleDailyActiveSpeakerChanged,
	handleDailyDataMessageEvent,
	handleDailyErrorEvent,
	handleDailyParticipantDisconnected,
	handleDailyParticipantJoined,
	handleDailyParticipantUpdate,
	handleDailyTrackUpdatedEvent,
} from "./dailyEventHandlers";
import { DailyLocalStream } from "./dailyLocalStream";
import { DailyLocalUser } from "./dailyLocalUser";
import { DailyRemoteUser } from "./dailyRemoteUser";

export class DailySession extends EVCEmitter<IEVCSessionEventMap> implements ISession {
	static lowQualityBaseBackoffMs: number = 5000;
	static lowQualityMaxBackoffMs: number = 30000;

	roomGuid?: string | undefined;
	localUserId?: string | undefined;
	connectionStatus: SessionConnectionStatus;
	localUser: DailyLocalUser;
	call: DailyCall;
	participants: DailyRemoteUser[] = [];
	_dominantSpeaker: DailyRemoteUser | null = null;

	// Handle shifting between low and high quality mode.
	// To avoid rapid oscillating between modes, use an exponential backoff to slow down changes for users who repeatedly hit high CPU load
	private _isCPULoadHigh: boolean = false;
	private _isInLowQualityBackoffPeriod: boolean = false;
	private _isInLowQualityMode: boolean = false;
	private _lowQualityModeOccurrences: number = 0;

	private _isAdmitted: boolean = false;
	private _cleanupEventListeners: () => void;

	constructor(user: DailyLocalUser) {
		super();
		this.localUser = user;
		this.connectionStatus = "not-connected";
		this.call = user.dailyCall;
		this._cleanupEventListeners = this.setupEventListeners();
	}

	processError(error: unknown): VendorError {
		return dailyErrorToVendorError(error);
	}

	// Not used in Daily
	refreshMedia(): void {
		return;
	}

	sendDataMessage(message: string): void {
		this.call.sendAppMessage(message);
	}

	async addScreenShare(stream: DailyLocalStream): Promise<boolean> {
		if (stream.mediaStream) {
			this.call.startScreenShare({ mediaStream: stream.mediaStream });
			return Promise.resolve(true);
		} else {
			return Promise.resolve(false);
		}
	}

	async removeScreenShare(_stream: ILocalStream, _removeAudio?: boolean | undefined): Promise<boolean> {
		this.call.stopScreenShare();
		return Promise.resolve(true);
	}

	/**
	 * Initialize subscription status for a user
	 * @param _identity
	 * @param _jwt
	 * @returns True
	 */
	enableUserMedia(_identity: string, _jwt: string): Promise<boolean> {
		this._isAdmitted = true;
		this.participants.forEach((participant) => {
			this.call.updateParticipant(participant.getUserGuid(), {
				setSubscribedTracks: { screenVideo: true, audio: true, screenAudio: true },
			});
		});
		return Promise.resolve(true);
	}

	async connect(connectOptions: IConnectOptions): Promise<boolean> {
		const { jwt } = connectOptions;

		this._isAdmitted = !connectOptions.isInWaitingRoom;
		await this.call.join({
			url: connectOptions.info.roomIdentifier,
			subscribeToTracksAutomatically: false,
			token: connectOptions.info.token,
		});
		// Setup participant monitoring
		this.connectionStatus = "connected";
		const participants = this.call.participants();
		const keys = Object.keys(participants);
		const roomInfo = await this.call.room();
		this.roomGuid = roomInfo && "id" in roomInfo ? roomInfo.id : undefined;
		keys.forEach((participantId) => {
			if (participantId !== "local") {
				const remoteDailyParticipant = participants[participantId];
				const remoteUser = new DailyRemoteUser(remoteDailyParticipant, this.call);
				this.participants.push(remoteUser);
			}
		});

		if (connectOptions.isInWaitingRoom && jwt) {
			// We set the default subscribe rule to be none to ensure there isn't a race condition with receiving remote video/audio
			// Now allow data track messages to be subscribed to, in order to ensure we can catch a join call signal
			await allowDataTrackMessages(jwt);
		}
		this.emit("sessionConnected", { type: "sessionConnected", session: this });
		return Promise.resolve(true);
	}

	async disconnect(): Promise<void> {
		await this.call.leave();
		this._cleanupEventListeners();
		await this.call.destroy();
	}

	// Daily has no publish concept, so this is unnecessary
	publish(
		_kind: DeviceKind,
		_expectedState: boolean,
		_device: ILocalStream,
		_recycleMedia?: boolean,
	): Promise<boolean> {
		return Promise.resolve(true);
	}

	async getRoomInfo(jwt: string): Promise<IConnectOptions> {
		const dto = await makeRequest<IConnectResponseDTO>("api/VideoCall/GetRoomInfo", "GET", jwt);
		const options: IConnectOptions = {
			logLevel: 0,
			isLowBandwidth: false,
			isInWaitingRoom: false,
			jwt: jwt,
			info: dto,
		};
		return options;
	}

	getRemoteParticipants(): DailyRemoteUser[] {
		return this.participants;
	}

	getRemoteParticipant(identity: string): DailyRemoteUser | null {
		const participant = this.participants.find((p) => p.getUserIdentity() === identity);
		return participant ?? null;
	}

	getLocalParticipant(): ILocalUser | null {
		return this.localUser;
	}

	getDominantSpeaker(): DailyRemoteUser | null {
		return this._dominantSpeaker;
	}

	getIsAdmitted(): boolean {
		return this._isAdmitted;
	}

	setUseLowQualityMode(isCPULoadHigh?: boolean): void {
		if (isCPULoadHigh !== undefined) {
			this._isCPULoadHigh = isCPULoadHigh;
		}

		const shouldUseLowQuality = this._isCPULoadHigh || this._isInLowQualityBackoffPeriod;
		if (shouldUseLowQuality === this._isInLowQualityMode) {
			return;
		}

		if (shouldUseLowQuality && !this._isInLowQualityMode) {
			// enter low quality mode
			this._setAreRemoteParticipantsInLowQuality(true);
			void this.call.updateSendSettings({ video: "bandwidth-optimized" });
			this._isInLowQualityMode = true;
			this._isInLowQualityBackoffPeriod = true;

			// Start exponential backoff to prevent rapid oscillation between modes
			exponentialBackoff(
				DailySession.lowQualityBaseBackoffMs,
				DailySession.lowQualityMaxBackoffMs,
				this._lowQualityModeOccurrences,
				() => {
					this._isInLowQualityBackoffPeriod = false;
					// Call setLowQualityMode to check if load dropped during backoff period
					this.setUseLowQualityMode();
				},
			);
			this._lowQualityModeOccurrences++;
		} else if (!shouldUseLowQuality && this._isInLowQualityMode) {
			// exit low quality mode
			this._setAreRemoteParticipantsInLowQuality(false);
			void this.call.updateSendSettings({ video: "bandwidth-and-quality-balanced" });
			this._isInLowQualityMode = false;
		}
	}

	private _setAreRemoteParticipantsInLowQuality(setToLowQuality: boolean): void {
		this.participants.forEach((participant) => {
			participant.setUseLowQuality(setToLowQuality);
		});
	}

	/**
	 * Sets up event listeners on a user for events that should bubble from the user to the session
	 * @param user
	 */
	setupUserEventListeners(user: DailyRemoteUser): void {
		user.on("screenShareStarted", (args) => {
			this.emit("screenShareStarted", { type: "screenShareStarted", participant: args.participant });
		});

		user.on("screenShareStopped", (args) => {
			this.emit("screenShareStopped", { type: "screenShareStopped", participant: args.participant });
		});
	}

	/**
	 * Clean up event listeners on a user
	 * @param user
	 */
	cleanupUserEventListener(user: DailyRemoteUser): void {
		user.removeAllListeners("screenShareStarted");
		user.removeAllListeners("screenShareStopped");
	}

	/**
	 * Set up event listeners to transform Daily events into EVC-formatted events
	 * @returns cleanup function to remove event listeners
	 */
	setupEventListeners(): () => void {
		const participantJoined = (event: DailyEventObject<"participant-joined">): void => {
			handleDailyParticipantJoined(event, this);
		};

		const participantDisconnected = (event: DailyEventObject<"participant-left">): void => {
			handleDailyParticipantDisconnected(event, this);
		};

		const handleTrackEvent = (event: DailyEventObject<"track-started" | "track-stopped">): void => {
			handleDailyTrackUpdatedEvent(event, this);
		};

		const handleActiveSpeakerChange = (event: DailyEventObjectActiveSpeakerChange): void => {
			handleDailyActiveSpeakerChanged(event, this);
		};

		const handleErrorEvent = (event: DailyEventObjectFatalError): void => {
			handleDailyErrorEvent(event, this);
		};

		const dataMessageReceived = (event: DailyEventObject<"app-message">): void => {
			handleDailyDataMessageEvent(event, this);
		};

		const updateUser = (event: DailyEventObjectParticipant): void => {
			handleDailyParticipantUpdate(event, this);
		};

		const handleCpuLoad = (event: DailyEventObject<"cpu-load-change">): void => {
			handleCpuLoadChangeEvent(event, this);
		};

		this.call.on("participant-joined", participantJoined.bind(this));
		this.call.on("participant-left", participantDisconnected.bind(this));
		this.call.on("track-started", handleTrackEvent.bind(this));
		this.call.on("track-stopped", handleTrackEvent.bind(this));
		this.call.on("active-speaker-change", handleActiveSpeakerChange.bind(this));
		this.call.on("error", handleErrorEvent.bind(this));
		this.call.on("app-message", dataMessageReceived.bind(this));
		this.call.on("participant-updated", updateUser.bind(this));
		this.call.on("cpu-load-change", handleCpuLoad.bind(this));

		return () => {
			this.call.off("participant-joined", participantJoined);
			this.call.off("participant-left", participantDisconnected);
			this.call.off("track-started", handleTrackEvent);
			this.call.off("track-stopped", handleTrackEvent);
			this.call.off("active-speaker-change", handleActiveSpeakerChange);
			this.call.off("error", handleErrorEvent);
			this.call.off("app-message", dataMessageReceived);
			this.call.off("participant-updated", updateUser);
		};
	}

	/**
	 * Remove a user from the backing participant array and update the application layer
	 * @param index - The index of the user to remove
	 */
	handleParticipantDisconnectFromArrayIndex(index: number): void {
		const user = this.participants[index];
		this.participants.splice(index, 1);

		if (user) {
			this.emit("participantDisconnected", {
				type: "participantDisconnected",
				participant: user,
			});

			this.cleanupUserEventListener(user);
		}
	}
}
