/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Shared state for the hardware test
 * @author Tara Feldstein
 * @module Epic.VideoApp.State.HardwareTest
 */
import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";
import {
	DeviceStatus,
	DeviceStatusSubtype,
	HardwareTestError,
	HardwareTestStatus,
	HardwareTestTab,
	IDeviceState,
	IHardwareTestCurrentTab,
} from "~/types";
import { isOnIOSVersion } from "~/utils/os";
import { VendorError } from "~/web-core/interfaces";
import { IDeviceUpdate } from "~/web-core/vendor/twilio";

/// TYPES ///
export interface IHardwareTestState {
	// Status of the devices
	readonly cameraState: IDeviceState;
	readonly microphoneState: IDeviceState;
	readonly speakerState: IDeviceState;
	readonly vendorError: VendorError | null;
	readonly hardwareTestTab: IHardwareTestCurrentTab;
	readonly tabButtonNeedingFocus: HardwareTestTab[];
	skipHardwareTest: boolean;
	/// When set to false will display the toggle in the devices tray within a call instead
	displaySkipHardwareTestToggleInLobby: boolean;
	notFirstSkip: boolean;
	isIPadCameraBugged: boolean;
}

/// INIT ///

export function getInitialState(isDisconnecting: boolean = false): IHardwareTestState {
	const initialStatus = isDisconnecting ? DeviceStatus.unknown : DeviceStatus.testing;

	return {
		cameraState: { status: initialStatus, errorType: DeviceStatusSubtype.none },
		microphoneState: { status: initialStatus, errorType: DeviceStatusSubtype.none },
		speakerState: { status: initialStatus, errorType: DeviceStatusSubtype.none },
		vendorError: null,
		hardwareTestTab: { tab: HardwareTestTab.closed, isAutoSelected: true },
		tabButtonNeedingFocus: [],
		skipHardwareTest: false,
		displaySkipHardwareTestToggleInLobby: true,
		notFirstSkip: false,
		isIPadCameraBugged: false,
	};
}

/// HELPERS ///
/**
 * Pure helper function to get the appropriate hardware test tab for the device state
 * - If a hardware test tab is open (settings or background effects), then the tab never changes
 * - If hardware test tabs are closed, then opens the settings if there is an error or warning status
 * @param state hardware test state
 * @param deviceState the state of a video, microphone, or speaker device
 * @returns the hardware test tab that should be used
 */
function getTestTabForDeviceState(state: IHardwareTestState, deviceState: IDeviceState): HardwareTestTab {
	if (shouldOpenDeviceTab(state, deviceState)) {
		return HardwareTestTab.devices;
	}
	return state.hardwareTestTab.tab;
}

function getTestTabForAllDeviceState(
	state: IHardwareTestState,
	devicesUpdates: IDeviceUpdate[],
): HardwareTestTab {
	for (const update of devicesUpdates) {
		if (shouldOpenDeviceTab(state, deviceUpdateToDeviceState(update))) {
			return HardwareTestTab.devices;
		}
	}

	return state.hardwareTestTab.tab;
}

function deviceUpdateToDeviceState(update: IDeviceUpdate): IDeviceState {
	return { errorType: update.errorType, status: update.status };
}

function shouldOpenDeviceTab(state: IHardwareTestState, deviceState: IDeviceState): boolean {
	return (
		state.hardwareTestTab.tab === HardwareTestTab.closed &&
		(deviceState.status === DeviceStatus.error || deviceState.status === DeviceStatus.warning)
	);
}

function isWarningOrError(deviceStatus: DeviceStatus): boolean {
	return (
		deviceStatus === DeviceStatus.error ||
		deviceStatus === DeviceStatus.unknown ||
		deviceStatus === DeviceStatus.warning
	);
}

/// REDUCERS - only for use by /hooks/localTracks ///

export function addElementNeedsFocus(state: IHardwareTestState, tab: HardwareTestTab): IHardwareTestState {
	if (state.tabButtonNeedingFocus.includes(tab)) {
		return state;
	}

	return { ...state, tabButtonNeedingFocus: [...state.tabButtonNeedingFocus, tab] };
}

export function removeElementNeedsFocus(state: IHardwareTestState, tab: HardwareTestTab): IHardwareTestState {
	const newElmsNeedingFocus = state.tabButtonNeedingFocus.filter((listTab) => {
		return listTab !== tab;
	});
	return { ...state, tabButtonNeedingFocus: newElmsNeedingFocus };
}

export function closeTabAndFocusTabButton(
	state: IHardwareTestState,
	tabType: HardwareTestTab,
): IHardwareTestState {
	state = setHardwareTestTab(state, HardwareTestTab.closed);
	return addElementNeedsFocus(state, tabType);
}

export interface IDeviceInitialization {
	deviceUpdates: IDeviceUpdate[];
	error: VendorError;
}

export function setInitialHardwareState(
	state: IHardwareTestState,
	initialization: IDeviceInitialization,
): IHardwareTestState {
	const { deviceUpdates, error } = initialization;
	const hardwareTestTab = getTestTabForAllDeviceState(state, deviceUpdates);

	const newState = {
		...state,
		hardwareTestTab: { tab: hardwareTestTab, isAutoSelected: true, vendorError: error },
	};
	for (const update of deviceUpdates) {
		switch (update.device) {
			case "camera":
				newState.cameraState = deviceUpdateToDeviceState(update);
				break;
			case "mic":
				newState.microphoneState = deviceUpdateToDeviceState(update);
				break;
			case "speaker":
				newState.speakerState = deviceUpdateToDeviceState(update);
				break;
		}
	}

	return newState;
}

export function setCameraState(state: IHardwareTestState, deviceState: IDeviceState): IHardwareTestState {
	const hardwareTestTab = getTestTabForDeviceState(state, deviceState);

	const newState: IHardwareTestState = {
		...state,
		cameraState: deviceState,
		hardwareTestTab: { tab: hardwareTestTab, isAutoSelected: true },
	};
	return newState;
}

export function setMicrophoneState(state: IHardwareTestState, deviceState: IDeviceState): IHardwareTestState {
	const hardwareTestTab = getTestTabForDeviceState(state, deviceState);

	const newState: IHardwareTestState = {
		...state,
		microphoneState: deviceState,
		hardwareTestTab: { tab: hardwareTestTab, isAutoSelected: true },
	};
	return newState;
}

export function setSpeakerState(state: IHardwareTestState, deviceState: IDeviceState): IHardwareTestState {
	const hardwareTestTab = getTestTabForDeviceState(state, deviceState);

	const newState: IHardwareTestState = {
		...state,
		speakerState: deviceState,
		hardwareTestTab: { tab: hardwareTestTab, isAutoSelected: true },
	};
	return newState;
}

export function setCameraSuccess(state: IHardwareTestState): IHardwareTestState {
	let newState: IHardwareTestState = {
		...state,
		cameraState: {
			status: DeviceStatus.success,
			errorType: DeviceStatusSubtype.none,
		},
	};
	newState = clearVendorErrorIfNecessary(newState);
	return newState;
}

export function setMicrophoneSuccess(state: IHardwareTestState): IHardwareTestState {
	let newState: IHardwareTestState = {
		...state,
		microphoneState: {
			status: DeviceStatus.success,
			errorType: DeviceStatusSubtype.none,
		},
	};
	newState = clearVendorErrorIfNecessary(newState);
	return newState;
}

export function setHardwareTestTab(state: IHardwareTestState, newTab: HardwareTestTab): IHardwareTestState {
	if (state.hardwareTestTab.tab === newTab && !state.hardwareTestTab.isAutoSelected) {
		return state;
	}
	return { ...state, hardwareTestTab: { tab: newTab, isAutoSelected: false } };
}

export function setSpeakerSuccess(state: IHardwareTestState): IHardwareTestState {
	let newState: IHardwareTestState = {
		...state,
		speakerState: {
			status: DeviceStatus.success,
			errorType: DeviceStatusSubtype.none,
		},
	};
	newState = clearVendorErrorIfNecessary(newState);
	return newState;
}

/**
 * This helper function is called by the setSuccess functions to update
 * VendorError to be nothing in the case that there was an error,
 * but an update fixed the error to create a passing state
 * @param state - current state
 */
function clearVendorErrorIfNecessary(state: IHardwareTestState): IHardwareTestState {
	let { vendorError } = state;
	// pass false for allowOneError to make sure the error is only cleared when there are no device errors
	// don't care about iOS version for determining Vendor Error state
	if (
		vendorError &&
		getTestStatus(state, { allowOneError: false, isStandalone: false }) === HardwareTestStatus.passed
	) {
		vendorError = null;
	}
	return { ...state, vendorError };
}

function setVendorError(state: IHardwareTestState, error: VendorError): IHardwareTestState {
	const newState: IHardwareTestState = {
		...state,
		vendorError: error,
	};
	return newState;
}

export function setSkipHardwareTest(state: IHardwareTestState, flag: boolean): IHardwareTestState {
	return state.skipHardwareTest === flag ? state : { ...state, skipHardwareTest: flag };
}

export function setDisplaySkipHardwareTestToggleInLobby(
	state: IHardwareTestState,
	flag: boolean,
): IHardwareTestState {
	return state.displaySkipHardwareTestToggleInLobby === flag
		? state
		: { ...state, displaySkipHardwareTestToggleInLobby: flag };
}

export function setIsFirstHardwareTestSkip(state: IHardwareTestState, flag: boolean): IHardwareTestState {
	return state.notFirstSkip === flag ? state : { ...state, notFirstSkip: flag };
}

export function setIsIPadCameraBugged(state: IHardwareTestState): IHardwareTestState {
	if (state.isIPadCameraBugged) {
		return state;
	}
	const newState = {
		...state,
		isIPadCameraBugged: true,
		cameraState: {
			status: DeviceStatus.unknown,
			errorType: DeviceStatusSubtype.unknown,
		},
	};
	return newState;
}

/// SELECTORS ///

function getHardwareTestTab(state: IHardwareTestState): IHardwareTestCurrentTab {
	return state.hardwareTestTab;
}

function getCameraStatus(state: IHardwareTestState, allowOneError: boolean): DeviceStatus {
	const { status } = state.cameraState;
	if (status !== DeviceStatus.error && status !== DeviceStatus.unknown) {
		return status;
	}
	// treat camera failure as a warning, if we're allowing one device to fail (used by lobby)
	return allowOneError && getNumDeviceErrors(state) === 1 ? DeviceStatus.warning : status;
}

function getCameraError(state: IHardwareTestState): DeviceStatusSubtype {
	return state.cameraState.errorType;
}

function getMicrophoneStatus(state: IHardwareTestState, allowOneError: boolean): DeviceStatus {
	const { status } = state.microphoneState;
	if (status !== DeviceStatus.error && status !== DeviceStatus.unknown) {
		return status;
	}
	// treat microphone failure as a warning, if we're allowing one device to fail (used by lobby)
	return allowOneError && getNumDeviceErrors(state) === 1 ? DeviceStatus.warning : status;
}

function getMicrophoneError(state: IHardwareTestState): DeviceStatusSubtype {
	return state.microphoneState.errorType;
}

function getSpeakerStatus(state: IHardwareTestState): DeviceStatus {
	return state.speakerState.status;
}

function getSpeakerError(state: IHardwareTestState): DeviceStatusSubtype {
	return state.speakerState.errorType;
}

function getVendorError(state: IHardwareTestState): VendorError | null {
	return state.vendorError;
}

interface ITestStatusParams {
	allowOneError: boolean;
	isStandalone: boolean;
}

/**
 * Get the current hardware test status
 * @param allowOneError Flag to indicate one device specific error should be allowed, used by the lobby to allow continuing with camera or microphone disabled
 */
function getTestStatus(state: IHardwareTestState, options: ITestStatusParams): HardwareTestStatus {
	const anyTestsOngoing =
		state.cameraState.status === DeviceStatus.testing ||
		state.microphoneState.status === DeviceStatus.testing ||
		state.speakerState.status === DeviceStatus.testing;
	if (anyTestsOngoing) {
		return HardwareTestStatus.testing;
	}

	const threshold = options.allowOneError ? 1 : 0;

	// consider the hardware test passed as long as either the camera or mic worked
	if (getNumDeviceErrors(state) > threshold) {
		return HardwareTestStatus.failed;
	}

	// fail the standalone hardware test if on an affected iOS 14.2 device
	if (options.isStandalone && isOnIOSVersion("14_2")) {
		return HardwareTestStatus.failed;
	}

	return HardwareTestStatus.passed;
}

/**
 * Checks if any devices have warnings or errors (or an unknown status, which could be an error). Similar to getNumDeviceErrors, except this reducer includes warning statuses
 * @param state Hardware test state for reducer
 * @returns true if hardware test has warnings or errors
 */
function hasDetectedIssues(state: IHardwareTestState, skipSpeakerCheck: boolean): boolean {
	return (
		isWarningOrError(state.cameraState.status) ||
		isWarningOrError(state.microphoneState.status) ||
		(!skipSpeakerCheck && isWarningOrError(state.speakerState.status))
	);
}

/**
 * Helper function to get the number of device errors
 */
function getNumDeviceErrors(state: IHardwareTestState): number {
	let errorCount = 0;

	if (
		state.cameraState.status === DeviceStatus.error ||
		state.cameraState.status === DeviceStatus.unknown
	) {
		errorCount += 1;
	}

	if (
		state.microphoneState.status === DeviceStatus.error ||
		state.microphoneState.status === DeviceStatus.unknown
	) {
		errorCount += 1;
	}

	if (
		state.speakerState.status === DeviceStatus.error ||
		state.speakerState.status === DeviceStatus.unknown
	) {
		errorCount += 1;
	}

	return errorCount;
}

function getTabButtonsNeedingFocus(state: IHardwareTestState): HardwareTestTab[] {
	return state.tabButtonNeedingFocus;
}

// Calculates the error type that should be displayed on the main card
function getHardwareTestError(state: IHardwareTestState): HardwareTestError {
	const { cameraState, microphoneState } = state;

	// Determine which device has the error to display
	let errorType: DeviceStatusSubtype;
	let errorIsCamera = false;
	if (cameraState.status === DeviceStatus.error) {
		errorType = cameraState.errorType;
		errorIsCamera = true;
	} else if (microphoneState.status === DeviceStatus.error) {
		errorType = microphoneState.errorType;
	} else {
		return HardwareTestError.none;
	}

	switch (errorType) {
		case DeviceStatusSubtype.general:
			return HardwareTestError.generalError;
		case DeviceStatusSubtype.hardwareError:
			return errorIsCamera ? HardwareTestError.cameraError : HardwareTestError.microphoneError;
		case DeviceStatusSubtype.permissionsError:
			return HardwareTestError.permissionsError;
		case DeviceStatusSubtype.unknown:
			return HardwareTestError.unknown;
	}

	return HardwareTestError.none;
}

function getSkipHardwareTest(state: IHardwareTestState): boolean {
	return state.skipHardwareTest;
}

function getDisplaySkipHardwareTestToggleInLobby(state: IHardwareTestState): boolean {
	return state.displaySkipHardwareTestToggleInLobby;
}

function getIsFirstHardwareTestSkip(state: IHardwareTestState): boolean {
	return state.notFirstSkip;
}

function getIsIPadCameraBugged(state: IHardwareTestState): boolean {
	return state.isIPadCameraBugged;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		setCameraState,
		setMicrophoneState,
		setCameraSuccess,
		setMicrophoneSuccess,
		setVendorError,
		setHardwareTestTab,
		removeElementNeedsFocus,
		addElementNeedsFocus,
		setSkipHardwareTest,
		setDisplaySkipHardwareTestToggleInLobby,
		setIsFirstHardwareTestSkip,
		setInitialHardwareState,
		closeTabAndFocusTabButton,
		setIsIPadCameraBugged,
	},
	selectors: {
		getCameraStatus,
		getMicrophoneStatus,
		getSpeakerStatus,
		getCameraError,
		getMicrophoneError,
		getSpeakerError,
		getVendorError,
		getTestStatus,
		getNumDeviceErrors,
		getHardwareTestError,
		getHardwareTestTab,
		hasDetectedIssues,
		getTabButtonsNeedingFocus,
		getSkipHardwareTest,
		getDisplaySkipHardwareTestToggleInLobby,
		getIsFirstHardwareTestSkip,
		getIsIPadCameraBugged,
	},
});
store.addSharedState(builtState.sharedState, "hardwareTest");

export const {
	actionCreators: hardwareTestActions,
	useSharedState: useHardwareTestState,
	sharedState: state,
} = builtState;
