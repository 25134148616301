/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Implements virtual background initialization and management for Daily
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Vendor.Daily.Helpers.DailyBackgroundManager
 */

import { DailyCall, DailyEventObjectNonFatalError } from "@daily-co/daily-js";
import { BackgroundSettings } from "~/types/backgrounds";
import { ImageBackgroundOnLoad, VirtualBackgroundLoader } from "~/web-core/helpers/virtualBackgroundLoader";

export class DailyBackgroundManager extends VirtualBackgroundLoader {
	backgroundImageBytesMap: Map<string, ArrayBuffer>;
	private _testBackgroundCall: DailyCall;

	constructor(testBackgroundCall: DailyCall) {
		super();
		this.backgroundImageBytesMap = new Map<string, ArrayBuffer>();
		this._testBackgroundCall = testBackgroundCall;
	}

	private imageProcessorInitialize: ImageBackgroundOnLoad = async (
		background: BackgroundSettings,
		_img: HTMLImageElement,
	): Promise<boolean> => {
		if (background.type !== "image") {
			return false;
		}
		try {
			const backgroundImageBytes = await fetch(background.src).then((response) =>
				response.arrayBuffer(),
			);
			this.backgroundImageBytesMap.set(background.path, backgroundImageBytes);
			return true;
		} catch (error) {
			return false;
		}
	};

	async initializeBackgroundProcessors(
		backgrounds: BackgroundSettings[],
		availableBackgroundsCount: number,
	): Promise<void> {
		if ((await this.testApplyBackgroundProcessor()) === false) {
			this.emit("backgroundProcessorsDone", {
				type: "backgroundProcessorsDone",
				status: "failed",
				successfulProcessors: [],
			});
			return;
		}
		return this.validateProcessors(
			backgrounds,
			availableBackgroundsCount,
			undefined,
			this.imageProcessorInitialize,
		);
	}

	/**
	 * Applies a test processor to the preview track to validate that backgrounds can load before displaying background options
	 * @returns Promise that resolves true if a background is able to successfully apply, false otherwise
	 */
	async testApplyBackgroundProcessor(): Promise<boolean> {
		await this._testBackgroundCall.startCamera();
		this._testBackgroundCall.setLocalVideo(true);

		const validateProcessorPromise = new Promise<boolean>((resolve) => {
			const onInputSettingsUpdated = (): void => {
				this._testBackgroundCall.off("input-settings-updated", onInputSettingsUpdated);
				this._testBackgroundCall.off("nonfatal-error", onNonFatalError);
				resolve(true);
			};

			const onNonFatalError = (event: DailyEventObjectNonFatalError): void => {
				if (event.type === "input-settings-error") {
					this._testBackgroundCall.off("input-settings-updated", onInputSettingsUpdated);
					this._testBackgroundCall.off("nonfatal-error", onNonFatalError);
					resolve(false);
				}
			};

			// Background application success event
			this._testBackgroundCall.on("input-settings-updated", onInputSettingsUpdated);
			// Background application failure event
			this._testBackgroundCall.on("nonfatal-error", onNonFatalError);
			void this._testBackgroundCall.updateInputSettings({
				video: { processor: { type: "background-blur", config: { strength: 1 } } },
			});
		});

		const success = await validateProcessorPromise;

		this._testBackgroundCall.setLocalVideo(false);
		void this._testBackgroundCall.updateInputSettings({ video: { processor: { type: "none" } } });
		return success;
	}
}
