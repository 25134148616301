/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Context to surface the session and local device stream to app components
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Components.VideoSessionProvider
 */

import { useDispatch } from "@epic/react-redux-booster";
import { navigate } from "@reach/router";
import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { getEndpointUrl } from "~/app/routes";
import { alertActions, combinedActions, hardwareTestActions, useRoomState } from "~/state";
import { localTrackActions, useLocalTrackState } from "~/state/localTracks";
import { IStreamDevices } from "~/types";
import { iOSDetectedAtVersion, isIOS } from "~/utils/os";
import { IEVCSessionDebugEvent } from "../events";
import { detectIPadCameraBug } from "../functions/detectIPadCameraBug";
import { unwrapInitializeError } from "../functions/errors";
import { initializeVendor } from "../functions/initializeVendor";
import { shouldShowPermissionWarning } from "../functions/utils";
import { useStoreError } from "../hooks/useStoreError";
import { useStream } from "../hooks/useStream";
import { ILocalUser } from "../interfaces";
import { ILocalStream } from "../interfaces/localStream";
import { ISession } from "../interfaces/session";
import DevicePermissionWarningOverlay from "./DevicePermissionWarningOverlay";

export interface ISessionContext {
	localDeviceStream: ILocalStream | undefined;
	session: ISession | undefined;
}

export const VideoSessionContext = React.createContext<ISessionContext>({
	localDeviceStream: undefined,
	session: undefined,
});

interface IProps {
	isVideoCall?: boolean;
}

/**
 * Context provider to surface the local user's stream and session to app components
 */
export const VideoSessionProvider: FC<IProps> = (props: PropsWithChildren<IProps>) => {
	const { children, isVideoCall } = props;

	const dispatch = useDispatch();
	const useLowBandwidth = useRoomState((selectors) => selectors.getIsLowBandwidthMode(), []);
	const vendor = useRoomState((selectors) => selectors.getVendor(), []);

	const trackAcquisitionStatus = useLocalTrackState(
		(selectors) => selectors.getLocalTrackAcquisitionStatus(),
		[],
	);

	const storeError = useStoreError();
	const [showWarning, setShowWarning] = useState<boolean>(false);
	const [user, setUser] = useState<ILocalUser>();
	const [session, setSession] = useState<ISession>();

	const localDeviceStream = useStream(user, "camera") as ILocalStream;

	useEffect(() => {
		const showMessage = (args: IEVCSessionDebugEvent): void => {
			dispatch(alertActions.postGenericAlert(args.message));
		};
		session?.on("debug", showMessage);
		return (): void => {
			session?.off("debug", showMessage);
		};
	}, [dispatch, session]);

	const initializeCallback = useCallback(async () => {
		try {
			const session = await initializeVendor(useLowBandwidth, vendor);
			setSession(session);
			setUser(session.localUser);

			// For certain iPad's running on iOs 14+, try and detect the issue where the media track is given to us as either
			// muted or interrupted depending on the vendor, and set the video track as bugged if it is
			if (isIOS() && iOSDetectedAtVersion("17+") && !iOSDetectedAtVersion("18+")) {
				void detectIPadCameraBug(session).then((isBugged) => {
					if (isBugged) {
						void session.localUser.deviceStream.removeLocalVideoTrack();
						dispatch(hardwareTestActions.setIsIPadCameraBugged());
					}
				});
			}

			const localStream = session.localUser.deviceStream;
			const deviceInitError = localStream.getDeviceInitializationError();

			// Check if there was an initialization error before setting specific device state
			if (deviceInitError) {
				dispatch(
					hardwareTestActions.setInitialHardwareState({
						deviceUpdates: deviceInitError.deviceUpdates,
						error: deviceInitError.error,
					}),
				);
				if (isVideoCall) {
					// Don't store vendor errors for the standalone hardware test
					// only video call users are authorized and expected to store vendor errors
					storeError(deviceInitError.error);
				}
			}
			const initialDeviceInfo: IStreamDevices = {
				isAudioEnabled: localStream.isEnabled("audio"),
				isVideoEnabled: localStream.isEnabled("video"),
			};

			dispatch(combinedActions.setInitialHardwareTestState(initialDeviceInfo));
		} catch (error) {
			storeError(unwrapInitializeError(error));
			void navigate(getEndpointUrl("/Error"));
		}
		dispatch(localTrackActions.setLocalTrackAcquisitionStatus("finished"));
		setShowWarning(false);
	}, [dispatch, isVideoCall, storeError, useLowBandwidth, vendor]);

	useEffect(() => {
		const runInitialize = async (): Promise<void> => {
			let showWarning = false;
			if (trackAcquisitionStatus === "notStarted") {
				try {
					// Use enumerateDevices to load permissions data
					const deviceInfos = await navigator.mediaDevices.enumerateDevices();
					showWarning = shouldShowPermissionWarning(deviceInfos);
					setShowWarning(showWarning);
				} catch (error) {
					// Continue if we get an error
				}
			}
			if (trackAcquisitionStatus === "notStarted" && !showWarning) {
				await initializeCallback();
			}
		};

		void runInitialize();
	}, [dispatch, initializeCallback, trackAcquisitionStatus]);

	// Use effect will let us try initialize whenever the stream updates
	useEffect(() => {
		if (!localDeviceStream) {
			return;
		}

		const deviceInitError = localDeviceStream.getDeviceInitializationError();

		if (deviceInitError) {
			return;
		}

		const streamDeviceInfo: IStreamDevices = {
			isAudioEnabled: localDeviceStream.isEnabled("audio"),
			isVideoEnabled: localDeviceStream.isEnabled("video"),
		};

		dispatch(combinedActions.setInitialHardwareTestState(streamDeviceInfo));
	}, [dispatch, session, localDeviceStream]);

	return (
		<VideoSessionContext.Provider
			value={{
				localDeviceStream,
				session,
			}}
		>
			{showWarning && <DevicePermissionWarningOverlay onContinue={initializeCallback} />}
			{children}
		</VideoSessionContext.Provider>
	);
};

VideoSessionProvider.displayName = "VideoSessionProvider";
