/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Modal alert to alert the user on inactivity and give them the option to disconnect or stay connected
 * @author Echo Cologlu
 * @module Epic.VideoApp.Components.Alerts.InactivityChoiceAlert
 */
import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useEffect, useRef } from "react";
import { ErrorTokenNames } from "~/features/generic-error/GenericError";
import { useDisconnect } from "~/hooks";
import { alertActions, errorPageActions } from "~/state";
import { IChoiceAlert, InactivityPopupTimeMS, PopupOffset, Timeout } from "~/types";
import AlertLocalTimer from "./AlertLocalTimer";
import ChoiceAlertBase from "./ChoiceAlertBase";

interface IProps {
	alert: IChoiceAlert;
}

export enum InactivityChoiceAlertTestIds {
	self = "InactivityChoiceAlert",
}

/**
 * Inactivity Choice alert component
 *
 * Renders a confirmation message that the user will be disconnected due to inactivity and two buttons to extend the period or leave the call.
 * Timeout waits ten minutes, then disconnects the user if the popup has not been dismissed.
 */
const InactivityChoiceAlert: FC<IProps> = (props) => {
	const { alert } = props;

	const dispatch = useDispatch();
	const disconnect = useDisconnect();

	const disconnectRef = useRef(disconnect);
	useEffect(() => {
		disconnectRef.current = disconnect;
	}, [disconnect]);

	const disconnectTimerRef = useRef<Timeout | undefined>(undefined);

	// Force disconnect at the end of the timeout
	useEffect(() => {
		disconnectTimerRef.current = setTimeout(() => {
			dispatch(alertActions.clearAlert());
			dispatch(
				errorPageActions.setErrorCard({
					title: ErrorTokenNames.timeoutHeader,
					message: ErrorTokenNames.timeoutMessage,
				}),
			);
			disconnectRef.current(false, false);
		}, InactivityPopupTimeMS + PopupOffset);

		return () => {
			clearTimeout(disconnectTimerRef.current);
		};
	}, [dispatch]);

	const leaveRoomCallback = useCallback(() => {
		dispatch(alertActions.clearAlert());
		disconnect(false, false);
	}, [dispatch, disconnect]);

	const extendCallback = useCallback(() => {
		// Hide alert
		dispatch(alertActions.clearAlert());
		// Update Redux to reflect that we want to extend inactivity
		dispatch(alertActions.setDidRequestInactivityExtension(true));
	}, [dispatch]);

	return (
		<ChoiceAlertBase
			data-testid={InactivityChoiceAlertTestIds.self}
			alert={alert}
			confirm={leaveRoomCallback}
			cancel={extendCallback}
		>
			<AlertLocalTimer initialTime={InactivityPopupTimeMS} />
		</ChoiceAlertBase>
	);
};

InactivityChoiceAlert.displayName = "InactivityChoiceAlert";

export default InactivityChoiceAlert;
