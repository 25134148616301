/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Button that can be rendered to directly open a given menu from a toast
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Header.Toasts.Buttons.OpenMenuButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import ActionButton from "~/components/Utilities/ActionButton";
import { useStrings } from "~/hooks";
import { uiActions, useUIState } from "~/state";
import { IDynamicMenuData } from "~/state/ui";
import { Menu } from "~/types";

export enum OpenMenuButtonTestIds {
	self = "OpenMenuButton",
}

enum TokenNames {
	openMenu = "OpenMenu",
}

interface IProps {
	menu: Menu;
	menuData?: IDynamicMenuData;
}

/**
 * The OpenMenuButton component
 * @param props The props ;)
 */
const OpenMenuButton: FC<IProps> = (props) => {
	const { menu, menuData } = props;
	const strings = useStrings("Toasts", Object.values(TokenNames));
	const visibleMenu = useUIState((selectors) => selectors.getVisibleMenu(), []);
	const dispatch = useDispatch();

	const openMenu = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			// cancel the event propagation so it won't trigger the ClickOutsideSection in ControlsHeader
			event?.nativeEvent.stopImmediatePropagation();

			if (visibleMenu === menu) {
				// Don't perform the menu update if it is already open
				return;
			}
			dispatch(
				uiActions.toggleVisibleMenu({
					menu: menu,
					menuData: menuData,
				}),
			);
		},
		[dispatch, menu, menuData, visibleMenu],
	);
	return (
		<ActionButton
			tone="neutral"
			priority="primary"
			onClick={openMenu}
			text={strings[TokenNames.openMenu]}
			aria-pressed={false}
			data-testid={OpenMenuButtonTestIds.self}
			aria-label={strings[TokenNames.openMenu]}
		/>
	);
};
OpenMenuButton.displayName = "OpenMenuButton";

export default React.memo(OpenMenuButton);
